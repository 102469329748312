import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    // backgroundColor: "#fff",
  },
  columnDiv: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    margin: "18px 10px",
    height: "90px",
  },
  valueRow: {
    marginTop: 10,
    width: 200,
    textAlign: "center",
  },
}));

const TeamComponent = ({ members }) => {
  const classes = useStyles();

  return (
    <div>
      {members.length > 0
        ? members.map((member) => (
            <div key={member._id} className={classes.root}>
              <div className={classes.columnDiv}>
                <div>
                  <strong>Name</strong>
                </div>
                <div className={classes.valueRow}>{member.name}</div>
              </div>
              <div className={classes.columnDiv}>
                <div>
                  <strong>Email</strong>
                </div>
                <div className={classes.valueRow}>{member.email}</div>
              </div>
              <div className={classes.columnDiv}>
                <div>
                  <strong>Status</strong>
                </div>
                <div className={classes.valueRow}>
                  {member.isActive ? (
                    <span style={{ color: "green", fontWeight: "900" }}>
                      Active
                    </span>
                  ) : (
                    <span style={{ color: "red", fontWeight: "900" }}>
                      Inactive
                    </span>
                  )}
                </div>
              </div>
              <div className={classes.columnDiv}>
                <div>
                  <strong>Action</strong>
                </div>
                <div
                  style={{
                    border: "1px solid",
                    padding: 7,
                    marginTop: 5,
                    cursor: "pointer",
                    borderRadius: 6,
                    backgroundColor: `${
                      member.isActive ? "#ff5a5e" : "#27ae60"
                    }`,
                    color: "white",
                  }}
                >
                  {member.isActive ? (
                    <span style={{ fontWeight: "900" }}>Deactivate</span>
                  ) : (
                    <span style={{ fontWeight: "900" }}>Activate</span>
                  )}
                </div>
              </div>
            </div>
          ))
        : null}
    </div>
  );
};

export default TeamComponent;
