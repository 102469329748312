import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import styled from "styled-components";
import { fetchZones, fetchLocation } from "../actions";
import { isAuth } from "../utils/AuthHelpers/AuthCookieHandler";

const ZonePage = () => {
  const { locationId } = useParams();
  const history = useHistory();
  // const { zones, location } = useSelector((state) => state);
  const [zones, setZones] = useState([]);
  const [location, setLocation] = useState([]);

  const goToSessionPage = (zone) => {
    history.push({
      pathname: `/locations/${zone.locationId}/zones/${zone._id}`,
      state: { zone, location },
    });
  };

  const goToSessionEditPage = (zone) => {
    history.push({
      pathname: `/locations/${zone.locationId}/zones/${zone._id}/edit`,
      state: { zone, location },
    });
  };

  const getData = async () => {
    const z = await fetchZones(locationId);
    setZones(z);

    const l = await fetchLocation(locationId);
    setLocation(l);
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line
  }, []);

  return (
    <div>
      <div>
        <div
          style={{
            display: "flex",
            fontSize: 30,
            fontWeight: "bold",
            color: "#27ae60",
            justifyContent: "center",
            marginBottom: "30px",
          }}
        >
          ops parking
        </div>
        <div
          style={{
            textAlign: "center",
            margin: "35px 0 0 0",
            fontSize: "30px",
            fontWeight: "bold",
          }}
        >
          Zones
        </div>
        <div
          style={{
            margin: "0 0 30px 0",
            fontSize: "10px",
            textAlign: "center",
            color: "gray",
          }}
        >
          {location.address}
        </div>
      </div>
      <div>
        {zones && zones.length > 0 ? (
          zones.map((loc) => {
            return (
              <div
                key={loc._id}
                style={{
                  padding: "14px",
                  borderRadius: "10px",
                  margin: "20px",
                  cursor: "pointer",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
                }}
              >
                <h3>Zone: {loc.zoneNumber}</h3>
                <p>
                  Est. Price: ${loc.price} / {loc.isHourly ? "Hourly" : "Daily"}
                </p>
                <p></p>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  {isAuth() && isAuth().role !== "customer" ? (
                    <ActionButton onClick={() => goToSessionEditPage(loc)}>
                      Edit Zone
                    </ActionButton>
                  ) : (
                    <ActionButton onClick={() => goToSessionPage(loc)}>
                      Park Here
                    </ActionButton>
                  )}
                </div>
              </div>
            );
          })
        ) : (
          <div style={{ textAlign: "center" }}>
            No zones for this location...
          </div>
        )}
      </div>
    </div>
  );
};

const ActionButton = styled.button`
  height: 50px;
  width: 200px;
  margin-top: 20px;
  cursor: pointer;
  border: none;
  border-radius: 5px;
  background-color: #27ae60;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  transition: all 250ms cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s;
  &:hover {
    background-color: #2cc36b;
  }
`;

export default ZonePage;
