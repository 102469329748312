import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import TextField from "@material-ui/core/TextField";
import styled from "styled-components";
import UserService from "../../services/UserService";
// import { getCookie } from "../../shared/AuthHelpers";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2, 4, 3),
    outlineStyle: "none",
    borderRadius: "10px",
  },
}));

const AddMemberModal = ({ getTeamMembers }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [memberName, setMemberName] = useState("");
  const [memberEmail, setMemberEmail] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const handleSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();
    const prodObj = {
      name: memberName,
      email: memberEmail,
    };

    try {
      const userService = new UserService();
      await userService.invite(prodObj);
      getTeamMembers();
      handleClose();
      setMemberName("");
      setMemberEmail("");
    } catch (err) {
      setErrorMessage(err);
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div
      style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      <InviteMemberModalButton
        onClick={handleOpen}
        style={{ marginBottom: "10px" }}
      >
        Add Parking Enforcer
      </InviteMemberModalButton>
      <br />
      <br />
      <hr />
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                cursor: "pointer",
              }}
            >
              {" "}
              <HighlightOffIcon onClick={handleClose} />{" "}
            </div>
            <h4>Team Invite</h4>
            <form onSubmit={handleSubmit} autoComplete="off">
              <TextField
                label="Member Name..."
                type="text"
                name="member_name"
                disabled={isLoading}
                value={memberName}
                required
                onChange={(e) => setMemberName(e.target.value)}
              />
              <br />
              <br />
              <TextField
                label="Member Email"
                type="email"
                name="member_email"
                disabled={isLoading}
                value={memberEmail}
                required
                onChange={(e) => setMemberEmail(e.target.value)}
              />
              <br />
              <br />
              <div
                style={{
                  color: "#27ae60",
                  width: "100%",
                  overflowWrap: "break-word",
                  fontSize: "10px",
                }}
              >
                {errorMessage}
              </div>
              <br />
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <InviteMemberModalButton disabled={isLoading}>
                  {isLoading ? "Loading..." : "Send Invite"}
                </InviteMemberModalButton>
              </div>
            </form>
          </div>
        </Fade>
      </Modal>
    </div>
  );
};

const InviteMemberModalButton = styled.button`
  height: 50px;
  width: 250px;
  margin-top: 20px;
  cursor: pointer;
  border: none;
  border-radius: 5px;
  background-color: #27ae60;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  transition: all 250ms cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s;
  &:hover {
    background-color: #2cc36b;
  }
`;

export default AddMemberModal;
