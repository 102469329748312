import React, { useEffect, useState } from "react";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import { useHistory } from "react-router-dom";
import { cl } from "../../theme/colors";
import { Button, makeStyles } from "@material-ui/core";
import { isAuth } from "../../utils/AuthHelpers/AuthCookieHandler";
import LocationStatCard from "../../components/shared/LocationStatCard";
import { capitalize, formatPrice } from "../../utils/FrontEndHelpers";
import BillingService from "../../services/BillingService";
import CirclueLoader from "../../components/shared/CircleLoader";

const useStyles = makeStyles((theme) => ({
  locationsNumber: {
    padding: 30,
    display: "flex",
    justifyContent: "space-evenly",
    boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
    borderRadius: 10,
    marginBottom: 20,
    [theme.breakpoints.down("md")]: {
      justifyContent: "unset",
      flexDirection: "column",
    },
  },
  statLine: {
    minWidth: 80,
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.down("md")]: {
      justifyContent: "unset",
    },
  },
}));

const Spots = () => {
  const history = useHistory();
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [locationRevenue, setLocationRevenue] = useState([]);
  const [currentMonthStats, setCurrentMonthStats] = useState({});
  const [changePercentage, setChangePercentage] = useState(0);

  const getRevenue = async (month = "") => {
    setIsLoading(true);
    // setSelectedMonth(month);
    const billingService = new BillingService();
    const { data } = await billingService.getRevenue({
      date: new Date(),
    });

    if (data?.vendorLocations?.length === 0) {
      setIsLoading(false);
      return;
    }

    if (data?.totalLocationRevenue?.length === 0) {
      setIsLoading(false);
      return;
    }

    setLocationRevenue(data.totalLocationRevenue);
    setCurrentMonthStats({ ...data, totalLocationRevenue: null });

    const cp = Number(data.revenueChange);
    const cPercentage = cp.toFixed(2);
    setChangePercentage(cPercentage);
    setIsLoading(false);
  };

  const goToLotRevenue = (lot) => {
    history.push({
      pathname: `/revenue/lot/${lot.locationId}`,
      state: lot,
    });
  };

  useEffect(() => {
    try {
      window.scroll({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    } catch (error) {
      // just a fallback for older browsers
      window.scrollTo(0, 0);
    }

    // make api request to get location stats here
    getRevenue();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      {isLoading ? (
        <div>
          <CirclueLoader />
        </div>
      ) : (
        <>
          <div style={{ fontWeight: 800, fontSize: 28 }}>
            Hello {capitalize(isAuth().name)}...
          </div>
          <div style={{ color: cl.nonary, marginBottom: 50 }}>
            Summary of your lots and their status is below.
          </div>
          <div className={classes.locationsNumber}>
            <div className={classes.statLine}>
              {/* // logo sec */}
              <div
                style={{
                  height: 35,
                  width: 35,
                  borderRadius: "100%",
                  backgroundColor: cl.brown,
                  marginRight: 10,
                  display: "flex",
                  justifyContent: "center",
                  textAlign: "center",
                }}
              >
                <LocationOnIcon
                  style={{
                    position: "relative",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    marginRight: 15,
                    color: cl.white,
                  }}
                  fontSize="small"
                />{" "}
              </div>

              {/* // info sec */}
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <div>Location Count</div>
                <div style={{ display: "flex" }}>
                  <div style={{ fontWeight: 800 }}>
                    {currentMonthStats.totalLocationCount}
                  </div>
                </div>
              </div>
            </div>
            <hr />

            <div className={classes.statLine}>
              {/* // logo sec */}
              <div
                style={{
                  height: 35,
                  width: 35,
                  borderRadius: "100%",
                  backgroundColor: cl.neutral,
                  marginRight: 10,
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <MonetizationOnIcon
                  style={{
                    position: "relative",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    marginRight: 15,
                    color: cl.white,
                  }}
                  fontSize="small"
                />
              </div>

              {/* // info sec */}
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <div>Total Paid Out</div>
                <div style={{ display: "flex" }}>
                  <div style={{ fontWeight: 800 }}>
                    {Number(currentMonthStats.totalLocationRevenueAllTime)
                      ? formatPrice(
                          currentMonthStats.totalLocationRevenueAllTime
                        )
                      : formatPrice("0")}
                  </div>
                </div>
              </div>
            </div>
            <hr />

            <div className={classes.statLine}>
              {/* // logo sec */}
              <div
                style={{
                  height: 35,
                  width: 35,
                  borderRadius: "100%",
                  backgroundColor: cl.primary,
                  marginRight: 10,
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <AttachMoneyIcon
                  style={{
                    position: "relative",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    marginRight: 15,
                    color: cl.white,
                  }}
                  fontSize="small"
                />
              </div>

              {/* // info sec */}
              <div
                className={classes.statLine}
                style={{ display: "flex", flexDirection: "column" }}
              >
                <div>Next Payout</div>
                <div style={{ display: "flex" }}>
                  <div style={{ fontWeight: 800 }}>
                    {formatPrice(Number(currentMonthStats.vendorTotalRevenue))}
                  </div>
                  <div style={{ display: "flex", color: cl.secondary }}>
                    <ArrowDropUpIcon
                      fontSize="medium"
                      style={{ color: cl.secondary }}
                    />{" "}
                    {Number(changePercentage) ? changePercentage : "0"}%
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <h1>Locations...</h1>
            <Button
              id="red"
              variant="contained"
              style={{
                marginLeft: 25,
                width: "8rem",
                color: "#fff",
                cursor: "pointer",
                backgroundColor: cl.primary,
              }}
              onClick={() => history.push("/locations/add")}
            >
              Add Location
            </Button>
          </div>
          <hr />
          <div className="rev-cards">
            {locationRevenue.map((lot) => (
              <LocationStatCard
                key={lot.locationId}
                lot={lot}
                goToLotRevenue={goToLotRevenue}
              />
            ))}
          </div>
        </>
      )}
    </div>
  );
};
export default Spots;
