import React, { useState, useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import { Redirect, Link, withRouter } from "react-router-dom";
import axios from "axios";
import styled from "styled-components";
import { authenticate, isAuth } from "../utils/AuthHelpers/AuthCookieHandler";

const LoginPage = ({ history }) => {
  useEffect(() => {
    try {
      window.scroll({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    } catch (error) {
      // just a fallback for older browsers
      window.scrollTo(0, 0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [values, setValues] = useState({
    email: "",
    password: "",
    buttonText: "Log In",
    error: "",
    success: "",
    isLoading: false,
  });

  const { email, password, buttonText, isLoading, error } = values;

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
      error: "",
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setValues({ ...values, buttonText: "Loading...", isLoading: true });
    axios
      .post("/api/v1/users/login", { email, password })
      .then((response) => {
        // console.log("SIGNIN_SUCCESS", response);

        // save response in local storage and cookie
        authenticate(response, () => {
          setValues({
            ...values,
            email: "",
            password: "",
            buttonText: "Done!",
            error: "",
            isLoading: false,
          });

          console.log("should push here");
          isAuth() && isAuth().role === "admin"
            ? history.push("/admin")
            : history.push("/dashboard");
          window.location.reload(false);
        });
      })
      .catch((error) => {
        // console.log("SIGNIN_ERROR", error.response.data.message);
        setValues({
          ...values,
          error: error.response.data.message,
          buttonText: "Log In",
          isLoading: false,
        });
      });
  };

  return (
    <div>
      {isAuth() && isAuth().role ? <Redirect to="/dashboard" /> : null}
      <div>
        <div>
          <img
            style={{ width: 280 }}
            src="https://d1kyth5cj5s868.cloudfront.net/OPS-parking-logo-b.png"
            alt="opsparking"
          />
        </div>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <TextField
              label="E-mail"
              onChange={(e) => handleChange(e)}
              type="email"
              name="email"
              disabled={isLoading}
              value={email}
              variant="outlined"
            />
          </div>

          <div className="form-group">
            <TextField
              label="Password"
              onChange={(e) => handleChange(e)}
              type="password"
              name="password"
              disabled={isLoading}
              value={password}
              variant="outlined"
            />
          </div>

          <div style={{ display: "flex", justifyContent: "center" }}>
            <div
              style={{
                color: "red",
                fontSize: "10px",
                height: "30px",
                width: "200px",
                textAlign: "center",
              }}
            >
              {error && error}
            </div>
          </div>

          <div style={{ textAlign: "center", marginTop: "4px" }}>
            <LoginButton
              disabled={isLoading}
              className="btn btn-primary"
              onClick={handleSubmit}
              id="signin_button"
              variant="contained"
              color="primary"
            >
              {buttonText}
            </LoginButton>
          </div>
        </form>
        <br />
        <div style={{ marginTop: "27px" }}>
          Don't already have an account? <Link to="/register">Sign Up</Link>
        </div>
        <br />
        <div style={{ marginTop: "6%", textAlign: "center" }}>
          <Link to="/forgot-password">Forgot Password?</Link>
        </div>
      </div>
    </div>
  );
};

const LoginButton = styled.button`
  height: 50px;
  width: 280px;
  margin-top: 20px;
  cursor: pointer;
  border: none;
  border-radius: 5px;
  background-color: #27ae60;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  transition: all 250ms cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s;
  &:hover {
    background-color: #2cc36b;
  }
`;

export default withRouter(LoginPage);
