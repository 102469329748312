import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import styled from "styled-components";
import swal from "sweetalert";
import EventIcon from "@material-ui/icons/Event";
import EventService from "../../services/EventService";
import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";
import { capitalize, isEmpty } from "../../utils/FrontEndHelpers";
import LocationService from "../../services/LocationService";
import { cl } from "../../theme/colors";
import moment from "moment";
import CircularIndeterminate from "../../components/shared/CircleLoader";

const useStyles = makeStyles((theme) => ({
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 280,
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
  },
  eventCards: {
    display: "grid",
    gridTemplateColumns: "50% 50%",
    gap: "10px",
    [theme.breakpoints.down("sm")]: {
      gridTemplateColumns: "100%",
      justifyItems: "center",
    },
  },
  eventCard: {
    margin: "20px 0",
    width: 250,
    boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: 250,
    borderRadius: 15,
    padding: 15,
  },
  deleteButton: {
    textAlign: "center",
    height: "34px",
    marginRight: "auto",
    marginLeft: "auto",
    color: "red",
    padding: "4px",
    cursor: "pointer",
    border: "1px solid",
    borderRadius: "10px",
    width: "100px",
  },
}));

const Events = () => {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  // const [lotNumber, setLotNumber] = useState("");
  const [startDate, setStartDate] = useState(new Date("2014-08-18T21:11:54"));
  const [endDate, setEndDate] = useState(new Date("2014-08-18T21:11:54"));
  const [errorMessages, setErrorMessages] = useState("");
  const [spotCount, setSpotCount] = useState("");
  const [eventName, setEventName] = useState("");
  const [locations, setLocations] = useState([]);
  const [location, setLocation] = useState({});
  const [newEvents, setNewEvents] = useState([]);

  const getLocations = async () => {
    const locationService = new LocationService();
    const { data } = await locationService.getMyLocations();
    setLocations(data.data.locations);
  };

  const getEvents = async (locationId) => {
    const eventService = new EventService();
    const { data } = await eventService.getAll(locationId);
    const firstArray = [...newEvents];
    const secondArray = data.data.events;

    if (firstArray.length === 0) {
      setNewEvents(secondArray);
      return;
    }

    var ids = new Set(firstArray.map((d) => d._id));
    var merged = [
      ...firstArray,
      ...secondArray.filter((d) => !ids.has(d._id) && ids),
    ];

    // remove duplicates between newEvents and data.data.events
    setNewEvents(merged);
  };

  const deleteEvent = async (eventId) => {
    try {
      // confirm delete
      const confirmDelete = await swal({
        title: "Are you sure?",
        text: "Once deleted, you will not be able to recover this member!",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      });

      if (!confirmDelete) {
        setIsLoading(false);
        return;
      }

      setIsLoading(true);
      const eventService = new EventService();
      await eventService.delete(eventId);
      const filteredEvents = newEvents.filter((ev) => ev._id !== eventId);
      setNewEvents(filteredEvents);

      // show success message
      swal("Poof! The event has been deleted!", {
        icon: "success",
      });

      setIsLoading(false);
    } catch (error) {
      console.log(error);
      swal(
        "Oops!",
        "Seems like something went wrong, please try again or Contact Us",
        "error"
      );
      setIsLoading(false);
    }
  };

  useEffect(() => {
    try {
      window.scroll({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    } catch (error) {
      // just a fallback for older browsers
      window.scrollTo(0, 0);
    }

    getLocations();
    getEvents();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = async () => {
    setIsLoading(true);

    const eventObject = {
      locationId: location._id,
      location,
      eventName,
      startDate,
      endDate,
      spotCount: Number(spotCount),
    };

    // make call here
    try {
      const eventService = new EventService();
      const nevent = await eventService.create(eventObject);
      console.log(nevent.data.data.event);
      setNewEvents([...newEvents, nevent.data.data]);
    } catch (err) {
      console.log(err);
      setErrorMessages(err.message);
    }

    setIsLoading(false);
  };

  // const handleChange = (event) => {
  //   setLotNumber(event.target.value);
  // };

  const handleSelect = async (val) => {
    setLocation(val.target.value);
  };

  return (
    <div>
      <div style={{ textAlign: "center" }}>
        <div
          style={{
            textTransform: "capitalize",
            fontSize: "26px",
            fontWeight: "900",
            marginBottom: "50px",
          }}
        >
          Add Event
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <div>
          <FormControl variant="outlined" style={{ width: "100%" }}>
            <InputLabel
              id="demo-simple-select-outlined-label"
              style={{ paddingLeft: 8 }}
              placeholder="Select a Location"
            >
              {" "}
              {isEmpty(location) ? locations?.name : "My Locations"}
            </InputLabel>
            <Select
              labelId="demo-simple-select-outlined-label"
              className={classes.textField}
              defaultValue={locations.length > 0 ? locations[0]?.id : ""}
              value={location}
              onChange={handleSelect}
              label="Location"
              required
            >
              {locations.map((loc, idx) => {
                return (
                  <MenuItem value={loc} key={idx}>
                    {capitalize(loc.name)}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </div>
        <div>
          <TextField
            label={"Event Name"}
            variant="outlined"
            required
            type="text"
            onChange={(e) => setEventName(e.target.value)}
            value={eventName}
            disabled={isLoading}
            inputProps={{
              step: 300, // 5 min
            }}
          />{" "}
          <br />
          <TextField
            label={"Spots Required"}
            variant="outlined"
            required
            type="number"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start"># </InputAdornment>
              ),
            }}
            placeholder={`Spots Reserved for Event`}
            onChange={(e) => setSpotCount(e.target.value)}
            value={spotCount}
            disabled={isLoading}
          />{" "}
        </div>
        <div>
          <TextField
            id="date"
            label="Start Date"
            type="date"
            required
            onChangeCapture={(e) => setStartDate(e.target.value)}
            defaultValue={startDate}
            className={classes.textField}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            id="date"
            label="End Date"
            type="date"
            required
            onChangeCapture={(e) => setEndDate(e.target.value)}
            defaultValue={endDate}
            className={classes.textField}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </div>
        {/* {errorLocNameMessages && (
          <div style={{ color: "red", textAlign: "center" }}>
            {errorLocNameMessages}
          </div>
        )} */}
        {errorMessages && (
          <div style={{ color: "red", textAlign: "center" }}>
            {errorMessages}
          </div>
        )}
        <div style={{ display: "flex", justifyContent: "center" }}>
          <SubmitLocationButton onClick={(e) => handleSubmit(e)}>
            Add
          </SubmitLocationButton>
        </div>
      </div>

      <h1 style={{ marginTop: 75 }}>Events</h1>
      <hr />
      {isLoading ? (
        <CircularIndeterminate />
      ) : newEvents.length === 0 ? (
        <div style={{ textAlign: "center" }}>
          Looks like you have not created any events...
        </div>
      ) : (
        <div className={classes.eventCards}>
          {newEvents.length > 0 &&
            newEvents.map((ev, idx) => {
              return (
                <div className={classes.eventCard} key={idx}>
                  <div style={{ display: "flex", height: 100 }}>
                    <div
                      style={{
                        height: 36,
                        width: 36,
                        borderRadius: "10px",
                        backgroundColor: cl.senary,
                        marginRight: 10,
                        display: "flex",
                        justifyContent: "center",
                        border: "none",
                      }}
                    >
                      <EventIcon
                        style={{
                          position: "relative",
                          top: "50%",
                          left: "50%",
                          transform: "translate(-50%, -50%)",
                          marginRight: 15,
                          color: cl.white,
                        }}
                        fontSize="small"
                      />
                    </div>
                    <div>
                      <div style={{ fontWeight: 800 }}>
                        {capitalize(ev.location?.name)}
                      </div>
                      <div style={{ color: cl.nonary, fontSize: 12 }}>
                        {ev.location?.address}
                      </div>
                    </div>
                  </div>
                  <div style={{ height: 50 }}>
                    <div>
                      {moment(
                        new Date(ev.startDate).setHours(
                          new Date(ev.startDate).getHours() + 8
                        )
                      ).format("MMM DD, YYYY")}
                      -{" "}
                      {moment(
                        new Date(ev.endDate).setHours(
                          new Date(ev.endDate).getHours() + 8
                        )
                      ).format("MMM DD, YYYY")}
                    </div>
                  </div>
                  <div
                    style={{
                      fontWeight: 800,
                      color: cl.tertiary,
                      fontSize: 25,
                      textAlign: "center",
                      height: 50,
                    }}
                  >
                    {capitalize(ev.eventName)}
                  </div>
                  <div
                    onClick={() => deleteEvent(ev._id)}
                    className={classes.deleteButton}
                  >
                    Delete
                  </div>
                </div>
              );
            })}
        </div>
      )}
    </div>
  );
};

const SubmitLocationButton = styled.button`
  height: 50px;
  width: 200px;
  margin-top: 20px;
  cursor: pointer;
  border: none;
  border-radius: 5px;
  background-color: ${cl.primary};
  color: #fff;
  font-size: 20px;
  font-weight: 600;
  transition: all 250ms cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s;
  &:hover {
    background-color: ${cl.secondary}};
    color: #fff;
  }
`;

export default Events;
