import React, { useState, useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import { isEmpty, roundUp } from "../../utils/FrontEndHelpers";
import LocationIcon from "@material-ui/icons/LocationOn";
import MoneyIcon from "@material-ui/icons/LocalAtm";
import styled from "styled-components";
import VehicleService from "../../services/VehicleService";
import { CircularProgress } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    padding: "0 10px",
  },
  addressDiv: {
    marginRight: theme.spacing(1),
  },
  lotDiv: {
    display: "flex",
    justifyContent: "center",
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  plateNumberInput: {
    width: "160px",
  },
  plateStateInput: {
    width: "120px",
  },
  hoursInput: {
    width: "90px",
    height: "51px",
    marginLeft: "6px !important",
  },
  licenseInputDiv: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
}));

const SessionInfoPage = ({ zoneObj, handleNext, setSession, sessionData }) => {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [feeState, setFeeState] = useState("");
  const [estimatedPrice, setEstimatedPrice] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [colors, setColors] = useState({
    1: true,
    2: false,
    3: false,
    4: false,
    5: false,
  });

  const feeAmount = zoneObj.zone.price < 10 ? 0.35 : 0.1; // this is a percentage either 10% or 35% of the rate
  const { first, second, third, fourth, fifth } = colors;
  const { hours, isHourly, vehiclePlate, vehicleState, rate } = sessionData;

  const setUpCosts = (givenHour) => {
    const f = roundUp(
      zoneObj.zone.isHourly
        ? zoneObj.zone.price * givenHour * feeAmount
        : zoneObj.zone.price * feeAmount
    ).toFixed(2);

    // exception added to make all zones that are 5 or less to have a fee of 1.75
    // const fee = zoneObj.zone.price < 5 ? 1.75 : f;

    setFeeState(f);

    if (zoneObj.zone.isHourly) {
      const calcRate = zoneObj.zone.isHourly
        ? zoneObj.zone.price * givenHour
        : zoneObj.zone.price;
      const calcFee = zoneObj.zone.isHourly
        ? zoneObj.zone.price * givenHour * feeAmount
        : zoneObj.zone.price * feeAmount;
      const calcEst = (calcRate + calcFee).toFixed(2);

      setEstimatedPrice(calcEst);

      // if (!isNaN(calcEst) && calcEst > 0) {
      //   const eFixed = calcEst.toFixed(2);
      //   setEstimatedPrice(eFixed);
      // }
    } else {
      const calcDailyRate = Number(f);
      setEstimatedPrice(zoneObj.zone.price + calcDailyRate);
    }
  };

  useEffect(() => {
    setUpCosts(hours);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleContinue = async () => {
    let licensePlateData;

    setIsLoading(true);
    const newFees = roundUp(
      isHourly ? rate * hours * feeAmount : rate * feeAmount
    );
    if (isEmpty(vehiclePlate)) {
      setErrorMessage("Please provide a valid license plate");
      setIsLoading(false);
      return;
    }
    if (isEmpty(vehicleState)) {
      setErrorMessage("Please provide a valid state for license plate");
      setIsLoading(false);
      return;
    }

    try {
      const vehicleInfo = { vehiclePlate, vehicleState };
      const vehicleService = new VehicleService();
      const {
        data: { data },
      } = await vehicleService.get(vehicleInfo);

      if (data.status === "ok") {
        licensePlateData = data.content;

        setSession({
          ...sessionData,
          fees: newFees,
          vehicleMake: licensePlateData.make,
          vehicleModel: licensePlateData.model,
          vehicleYear: licensePlateData.year,
          vehicleDescription: licensePlateData.vin_data.NCSABodyType,
        });
        setIsLoading(false);
        handleNext();
      }
    } catch (err) {
      setErrorMessage(
        "Please provide a valid license plate number and try again"
      );
      setIsLoading(false);
    }
  };

  const handleHourChange = (hour) => {
    setColors((prev) => ({ [hour]: !prev[hour] }));
    setUpCosts(hour);

    setSession({
      ...sessionData,
      hours: hour,
    });
  };

  const handleChange = (e) => {
    if (errorMessage) {
      setErrorMessage("");
    }

    setSession({ ...sessionData, [e.target.name]: e.target.value });
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      <div style={{ fontSize: 30, fontWeight: 900 }}>
        <div>Zone: {zoneObj.zone.zoneNumber}</div>
      </div>
      <div className={classes.addressDiv}>
        <div
          style={{
            textTransform: "capitalize",
            fontWeight: 900,
            fontSize: 22,
            marginBotton: 6,
          }}
        >
          {zoneObj.location.name}
        </div>
        <div style={{ display: "flex" }}>
          <div style={{ marginRight: 10 }}>
            <LocationIcon fontSize="small" />
          </div>
          <div style={{ fontSize: 12 }}>{zoneObj.location.address}</div>
        </div>
      </div>
      <div style={{ display: "flex", alignItems: "center" }}>
        <MoneyIcon style={{ fontSize: 21, marginRight: 10 }} />

        <div style={{ fontSize: 13 }}>
          ${zoneObj.zone.price} / {zoneObj.zone.isHourly ? "Hourly" : "Daily"}
        </div>
      </div>

      <div className={classes.licenseInputDiv}>
        <TextField
          label={"License Plate Number"}
          name="vehiclePlate"
          classes={{ root: classes.plateNumberInput }}
          InputLabelProps={{
            style: { fontSize: "14px" },
          }}
          inputProps={{
            maxLength: 8,
            style: { textTransform: "uppercase" },
          }}
          variant="outlined"
          onChange={handleChange}
          value={vehiclePlate}
          disabled={isLoading}
        />{" "}
        <div style={{ width: "20px" }}></div>
        <TextField
          label={"License State"}
          name="vehicleState"
          classes={{ root: classes.plateStateInput }}
          InputLabelProps={{
            style: { fontSize: "14px" },
          }}
          inputProps={{
            maxLength: 2,
            style: { textTransform: "uppercase" },
          }}
          variant="outlined"
          onChange={handleChange}
          value={vehicleState}
          disabled={isLoading}
        />{" "}
      </div>

      {zoneObj.zone.isHourly && (
        <>
          <div>How long do you want to park?</div>
          <GridContainer>
            <HoursRow>
              <HoursCell selected={first} onClick={() => handleHourChange(1)}>
                1
              </HoursCell>
              <HoursCell selected={second} onClick={() => handleHourChange(2)}>
                2
              </HoursCell>
              <HoursCell selected={third} onClick={() => handleHourChange(3)}>
                3
              </HoursCell>
            </HoursRow>
            <HoursRow>
              <HoursCell selected={fourth} onClick={() => handleHourChange(4)}>
                4
              </HoursCell>
              <HoursCell selected={fifth} onClick={() => handleHourChange(5)}>
                5
              </HoursCell>
              <TextField
                label={"Custom"}
                classes={{ root: classes.hoursInput }}
                name="hours"
                variant="outlined"
                type="number"
                onChange={(e) => handleHourChange(e.target.value)}
                value={hours}
                disabled={isLoading}
              />{" "}
            </HoursRow>
          </GridContainer>
        </>
      )}

      <div style={{ marginTop: "10px", fontWeight: "bold", fontSize: "16px" }}>
        Taxes & Fees: ${feeState}
      </div>
      <div
        style={{
          marginTop: "10px",
          fontWeight: "bold",
          fontSize: "18px",
          width: "100%",
        }}
      >
        Estimated Price:{" "}
        <span style={{ color: "#0f4fb8 !important" }}> ${estimatedPrice} </span>
        <span style={{ color: "gray", fontSize: "12px" }}>
          for {isHourly ? hours : 24} hour(s)
        </span>
      </div>
      <div style={{ color: "red", textAlign: "center", height: "20px" }}>
        {errorMessage && errorMessage}
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
        }}
      >
        {isLoading ? (
          <CircularProgress />
        ) : (
          <ContinueButton onClick={handleContinue}>Continue</ContinueButton>
        )}
      </div>
    </div>
  );
};

const GridContainer = styled.div``;

const HoursRow = styled.div`
  display: flex;
`;

const HoursCell = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 15px 40px;
  border: 1px solid #000;
  border-radius: 5px;
  margin: 5px;
  height: 60px;
  &:hover {
    background-color: #27ae60;
    color: #fff;
    border: 1px solid #27ae60;
  }
  background-color: ${(props) => (props.selected ? "#27ae60" : "none")};
`;

export const ContinueButton = styled.button`
  height: 50px;
  width: 280px;
  margin-top: 20px;
  cursor: pointer;
  border: none;
  border-radius: 5px;
  background-color: #27ae60;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  transition: all 250ms cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s;
  &:hover {
    background-color: #2cc36b;
  }
`;

export default SessionInfoPage;
