import React from "react";
import { Route, Redirect } from "react-router-dom";
import { isAuth } from "../../utils/AuthHelpers/AuthCookieHandler";

const SubbedRouteWrapper = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      isAuth() &&
      (isAuth().role === "customer" ||
        isAuth().role === "odogwu" ||
        isAuth().role === "enforcer" ||
        isAuth().role === "vendor") ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: "/login",
            state: { from: props.location },
          }}
        />
      )
    }
  ></Route>
);

export default SubbedRouteWrapper;
