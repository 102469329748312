import React, { useEffect, useState } from "react";
import axios from "axios";
import Typography from "@material-ui/core/Typography";
import styled from "styled-components";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import { isEmpty } from "../utils/FrontEndHelpers";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    marginTop: "87px",
    display: "flex",
    justifyContent: "space-around",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  button: {
    width: "250px",
    marginTop: "1rem",
    color: "#fff",
    "&:hover": {
      backgroundColor: "#f0f0f0",
    },
  },
  contactFormDiv: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginBottom: "70px",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  formDiv: {
    display: "flex",
    flexDirection: "column",
  },
  indiContactDiv: {
    margin: "0 70px",
    [theme.breakpoints.down("sm")]: {
      margin: "unset",
    },
  },
  imageStyle: {
    height: "400px",
    width: "auto",
    [theme.breakpoints.down("sm")]: {
      height: "230px",
    },
  },
}));

const ContactPage = () => {
  const classes = useStyles();
  const [values, setValues] = useState({
    name: "",
    email: "",
    message: "",
    error: "",
    isLoading: false,
    submitted: false,
  });

  useEffect(() => {
    try {
      window.scroll({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    } catch (error) {
      // just a fallback for older browsers
      window.scrollTo(0, 0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { name, email, message, isLoading, submitted, error } = values;

  const handleChange = (name) => (event) => {
    setValues({ ...values, [name]: event.target.value });
  };

  const sendEmail = () => {
    if (isEmpty(email) || isEmpty(name) || isEmpty(message)) {
      setValues({
        ...values,
        error: "Please complete all fields on the form",
        isLoading: false,
      });
    } else {
      setValues({ ...values, isLoading: true });
      axios
        .post("/api/v1/contact/contact-us", { name, email, message })
        .then(() => {
          setValues({
            ...values,
            name: "",
            email: "",
            message: "",
            error: "",
            isLoading: false,
            submitted: true,
          });
        })
        .catch((error) => {
          console.log("SENDINGN EMAIL ERROR", error);
          setValues({
            ...values,
            error: "Unable to send email",
            isLoading: false,
          });
        });
    }
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          fontSize: 30,
          fontWeight: "bold",
          color: "#27ae60",
          justifyContent: "center",
          marginBottom: "30px",
        }}
      >
        ops parking
      </div>
      <div style={{ textAlign: "center", marginTop: "87px" }}>
        <Typography
          component="h3"
          variant="h3"
          align="center"
          color="textPrimary"
          gutterBottom
          style={{ color: "#000" }}
        >
          Contact Us
        </Typography>
        {!submitted && (
          <div style={{ textAlign: "center", width: 280 }}>
            Have a Question? Would You like to hear about our customized
            pricing?
          </div>
        )}
      </div>
      <div className={classes.root}>
        <div className={classes.contactFormDiv}>
          {submitted ? (
            <span
              style={{
                color: "#27ae60",
                textAlign: "center",
                width: "200px",
                fontWeight: "800",
                marginTop: "30px",
              }}
            >
              Thank you for your message, someone from the team will get back to
              you as soon as possible.
            </span>
          ) : (
            <React.Fragment>
              <form noValidate autoComplete="off" className={classes.formDiv}>
                <TextField
                  label="Name"
                  variant="outlined"
                  disabled={isLoading}
                  value={name}
                  onChange={handleChange("name")}
                />
                <TextField
                  label="E-mail"
                  variant="outlined"
                  disabled={isLoading}
                  value={email}
                  onChange={handleChange("email")}
                />
                <TextField
                  label="Message"
                  disabled={isLoading}
                  value={message}
                  multiline
                  rows={5}
                  variant="outlined"
                  onChange={handleChange("message")}
                />
              </form>
              <SendButton
                className={classes.button}
                disabled={isLoading}
                onClick={sendEmail}
              >
                Send
              </SendButton>
              {error && <span>{error}</span>}
            </React.Fragment>
          )}
        </div>
      </div>
    </div>
  );
};

const SendButton = styled.button`
  height: 50px;
  width: 280px;
  margin-top: 20px;
  cursor: pointer;
  border: none;
  border-radius: 5px;
  background-color: #27ae60;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  transition: all 250ms cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s;
  &:hover {
    background-color: #2cc36b;
  }
`;

export default ContactPage;
