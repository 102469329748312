import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import AppBar from "@material-ui/core/AppBar";
import CssBaseline from "@material-ui/core/CssBaseline";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import MenuIcon from "@material-ui/icons/Menu";
import CloseIcon from "@material-ui/icons/Close";
import LocationOnIcon from "@material-ui/icons/LocationOn";

import Toolbar from "@material-ui/core/Toolbar";
import { makeStyles, useTheme } from "@material-ui/core/styles";

// icons
import GroupsIcon from "@material-ui/icons/GroupSharp";
// import GavelIcon from "@material-ui/icons/Gavel";
// import RevenueIcon from "@material-ui/icons/StayCurrentLandscape";
import EventIcon from "@material-ui/icons/Event";
import MembershipsIcon from "@material-ui/icons/Memory";
import CodesIcon from "@material-ui/icons/DiscFullOutlined";
import ReportsIcon from "@material-ui/icons/Assessment";
import DirectionsCarIcon from "@material-ui/icons/DirectionsCar";
import SettingsIcon from "@material-ui/icons/Settings";
import DashboardIcon from "@material-ui/icons/Dashboard";
import { isAuth } from "../../utils/AuthHelpers/AuthCookieHandler";

const drawerWidth = 240;
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    width: "100%",
  },
  drawer: {
    [theme.breakpoints.up("sm")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
    color: "#27ae60",
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    padding: "10px 38px 20px 38px",
  },
  closeMenuButton: {
    marginRight: "auto",
    marginLeft: 0,
  },
  sideMenuItems: {
    margin: "20px 0px 20px 0px",
  },
  logo: {
    display: "flex",
    cursor: "pointer",
    fontSize: "30px",
    fontWeight: "bold",
    color: "#27ae60",
    justifyContent: "center",
    alignItems: "center",
  },
}));

function Navbar({ children }) {
  const history = useHistory();
  const location = useLocation();
  const [odogwu, setOdogwu] = useState(false);
  const [isValet, setIsValet] = useState(false);
  const goToPage = (url) => {
    history.push(url);
  };

  // const menus = [
  //   { name: "Dashboard", icon: <DashboardIcon />, url: "/dashboard" },
  //   { name: "Team", icon: <GroupsIcon />, url: "/team" },
  //   // { name: "Revenue", icon: <RevenueIcon />, url: "/revenue" },
  //   { name: "Events", icon: <EventIcon />, url: "/events" },
  //   { name: "Locations", icon: <LocationOnIcon />, url: "/locations-vendor" },
  //   { name: "Memberships", icon: <MembershipsIcon />, url: "/members" },
  //   { name: "Codes", icon: <CodesIcon />, url: "/codes" },
  //   // { name: "Reports", icon: <ReportsIcon />, url: "/reports" },
  //   { name: "Profile", icon: <SettingsIcon />, url: "/profile" },
  // ];
  const classes = useStyles();
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  function handleDrawerToggle() {
    setMobileOpen(!mobileOpen);
  }

  useEffect(() => {
    if (!isAuth()) {
      history.push("/login");
      return;
    }

    if (isAuth() && isAuth().role) {
      if (isAuth().role === "vendor" || isAuth().role === "odogwu") {
        setOdogwu(true);
      }
    } else {
      setOdogwu(false);
    }

    if ((isAuth() && isAuth().role === "valet") || isAuth().role === "odogwu") {
      setIsValet(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const drawer = (
    <div>
      <List>
        <ListItem
          button
          onClick={() => goToPage("/dashboard")}
          className={classes.sideMenuItems}
          style={{
            backgroundColor: location.pathname.includes("/dashboard")
              ? "#27ae60"
              : null,
            color: location.pathname.includes("/dashboard") ? "white" : "black",
          }}
        >
          <DashboardIcon />
          <div className="space-here" style={{ width: 20 }}></div>{" "}
          <ListItemText primary={"Dashboard"} />
        </ListItem>

        {odogwu && (
          <ListItem
            button
            onClick={() => goToPage("/team")}
            className={classes.sideMenuItems}
            style={{
              backgroundColor: location.pathname.includes("/team")
                ? "#27ae60"
                : null,
              color: location.pathname.includes("/team") ? "white" : "black",
            }}
          >
            <GroupsIcon />
            <div className="space-here" style={{ width: 20 }}></div>{" "}
            <ListItemText primary={"Team"} />
          </ListItem>
        )}

        {odogwu && (
          <ListItem
            button
            onClick={() => goToPage("/events")}
            className={classes.sideMenuItems}
            style={{
              backgroundColor: location.pathname.includes("/events")
                ? "#27ae60"
                : null,
              color: location.pathname.includes("/events") ? "white" : "black",
            }}
          >
            <EventIcon />
            <div className="space-here" style={{ width: 20 }}></div>{" "}
            <ListItemText primary={"Events"} />
          </ListItem>
        )}

        {odogwu && (
          <ListItem
            button
            onClick={() => goToPage("/management")}
            className={classes.sideMenuItems}
            style={{
              backgroundColor: location.pathname.includes("/management")
                ? "#27ae60"
                : null,
              color: location.pathname.includes("/management")
                ? "white"
                : "black",
            }}
          >
            <ReportsIcon />
            <div className="space-here" style={{ width: 20 }}></div>{" "}
            <ListItemText primary={"Management"} />
          </ListItem>
        )}

        {isValet && (
          <ListItem
            button
            onClick={() => goToPage("/valet")}
            className={classes.sideMenuItems}
            style={{
              backgroundColor: location.pathname.includes("/valet")
                ? "#27ae60"
                : null,
              color: location.pathname.includes("/valet") ? "white" : "black",
            }}
          >
            <DirectionsCarIcon />
            <div className="space-here" style={{ width: 20 }}></div>{" "}
            <ListItemText primary={"Valet"} />
          </ListItem>
        )}

        {odogwu && (
          <>
            <ListItem
              button
              onClick={() => goToPage("/locations-vendor")}
              className={classes.sideMenuItems}
              style={{
                backgroundColor: location.pathname.includes("/locations-vendor")
                  ? "#27ae60"
                  : null,
                color: location.pathname.includes("/locations-vendor")
                  ? "white"
                  : "black",
              }}
            >
              <LocationOnIcon />
              <div className="space-here" style={{ width: 20 }}></div>{" "}
              <ListItemText primary={"Locations"} />
            </ListItem>

            <ListItem
              button
              onClick={() => goToPage("/members")}
              className={classes.sideMenuItems}
              style={{
                backgroundColor: location.pathname.includes("/members")
                  ? "#27ae60"
                  : null,
                color: location.pathname.includes("/members")
                  ? "white"
                  : "black",
              }}
            >
              <MembershipsIcon />
              <div className="space-here" style={{ width: 20 }}></div>{" "}
              <ListItemText primary={"Memberships"} />
            </ListItem>

            <ListItem
              button
              onClick={() => goToPage("/codes")}
              className={classes.sideMenuItems}
              style={{
                backgroundColor: location.pathname.includes("/codes")
                  ? "#27ae60"
                  : null,
                color: location.pathname.includes("/codes") ? "white" : "black",
              }}
            >
              <CodesIcon />
              <div className="space-here" style={{ width: 20 }}></div>{" "}
              <ListItemText primary={"Codes"} />
            </ListItem>
          </>
        )}

        <ListItem
          button
          onClick={() => goToPage("/profile")}
          className={classes.sideMenuItems}
          style={{
            backgroundColor: location.pathname.includes("/profile")
              ? "#27ae60"
              : null,
            color: location.pathname.includes("/profile") ? "white" : "black",
          }}
        >
          <SettingsIcon />
          <div className="space-here" style={{ width: 20 }}></div>{" "}
          <ListItemText primary={"Profile"} />
        </ListItem>
      </List>
    </div>
  );

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="Open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div
              onClick={() => goToPage("/dashboard")}
              className={classes.logo}
            >
              ops parking
            </div>
          </div>
        </Toolbar>
      </AppBar>

      <nav className={classes.drawer}>
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Hidden smUp implementation="css">
          <Drawer
            variant="temporary"
            anchor={theme.direction === "rtl" ? "right" : "left"}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            <IconButton
              onClick={handleDrawerToggle}
              className={classes.closeMenuButton}
            >
              <CloseIcon />
            </IconButton>
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden xsDown implementation="css">
          <Drawer
            className={classes.drawer}
            variant="permanent"
            classes={{
              paper: classes.drawerPaper,
            }}
          >
            <div className={classes.toolbar} />
            {drawer}
          </Drawer>
        </Hidden>
      </nav>
      <div className={classes.content}>
        <div className={classes.toolbar} style={{ marginBottom: 50 }}>
          <div style={{ height: 50 }}></div>
          {children}
        </div>
      </div>
    </div>
  );
}

export default Navbar;
