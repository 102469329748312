import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";
import { cl } from "../theme/colors";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    // backgroundColor: "#fff",
    [theme.breakpoints.down("md")]: {
      justifyContent: "unset",
    },
  },
  columnDiv: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    margin: "18px 10px",
    height: "90px",
  },
  valueRow: {
    marginTop: 10,
    width: 200,
    textAlign: "center",
  },
}));

const MembershipComponent = ({ members, deleteMember }) => {
  const classes = useStyles();

  return (
    <div>
      {members.length > 0
        ? members.map((member) => (
            <div key={member._id} className={classes.root}>
              <div className={classes.columnDiv}>
                <div>
                  <strong>License Plate</strong>
                </div>
                <div className={classes.valueRow}>{member.plate}</div>
              </div>
              <div className={classes.columnDiv}>
                <div>
                  <strong>Code</strong>
                </div>
                <div className={classes.valueRow}>
                  {member.code ? member.code : null}
                </div>
              </div>
              <div className={classes.columnDiv}>
                <div>
                  <strong>Overlaps</strong>
                </div>
                <div className={classes.valueRow}>
                  {member.overlaps_t ? member.overlaps_t : null}
                </div>
              </div>
              <div className={classes.columnDiv}>
                <div>
                  <strong>Valid Until Date</strong>
                </div>
                <div className={classes.valueRow}>
                  {moment(member.valid_until_date).format("MMMM Do YYYY")}
                </div>
              </div>
              <div className={classes.columnDiv}>
                <div>
                  <strong>Action</strong>
                </div>
                <div
                  onClick={() => deleteMember(member._id)}
                  style={{
                    border: "1px solid",
                    padding: 7,
                    marginTop: 5,
                    cursor: "pointer",
                    borderRadius: 6,
                    backgroundColor: cl.action,
                    color: "white",
                    "&:hover": {
                      backgroundColor: cl.action_hover,
                    },
                  }}
                >
                  <span style={{ fontWeight: "900" }}>Delete</span>
                </div>
              </div>
            </div>
          ))
        : null}
    </div>
  );
};

export default MembershipComponent;
