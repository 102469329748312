import APIkit from ".";

class MemberService {
  getmembers(locationId) {
    return APIkit.get(`/api/v1/locations/${locationId}/members`);
  }

  getAll() {
    return APIkit.get(`/api/v1/members`);
  }

  get({ locationId, validationId }) {
    return APIkit.get(
      `/api/v1/locations/${locationId}/members/${validationId}`
    );
  }

  create(data) {
    return APIkit.post("/api/v1/members", data);
  }

  update(id, data) {
    return APIkit.patch(`/api/v1/members/${id}`, data);
  }

  delete(id) {
    return APIkit.delete(`/api/v1/members/${id}`);
  }

  findById(title) {
    return APIkit.get(`/api/v1/members?id=${title}`);
  }
}

export default MemberService;
