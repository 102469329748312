import React, { useState, useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import styled from "styled-components";
import MemberService from "../../services/MemberService";
import swal from "sweetalert";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 280,
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
  },
}));

const AddMemberPage = () => {
  const classes = useStyles();
  const [isLoading, setLoading] = useState(false);
  const [licensePlate, setLicensePlate] = useState("");
  const [userName, setUsername] = useState("");
  const [userCode, setUsercode] = useState("");
  const [errorMessages, setErrorMessages] = useState("");
  const [endDate, setEndDate] = useState(new Date("2014-08-18T21:11:54"));

  useEffect(() => {
    try {
      window.scroll({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    } catch (error) {
      // just a fallback for older browsers
      window.scrollTo(0, 0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = async () => {
    try {
      const memberObj = {
        plate: licensePlate,
        valid_until_date: endDate,
        name: userName,
        code: userCode,
      };

      const memberService = new MemberService();
      await memberService.create(memberObj);

      swal("Success! Member has been added!", {
        icon: "success",
      });
    } catch (error) {
      console.log("error", error);
      setErrorMessages(error.response.data.message);
      swal(
        "Oops!",
        "Seems like something went wrong, please try again or Contact Us",
        "error"
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <div style={{ minHeight: "60vh" }}>
      <h1 style={{ textAlign: "center" }}>Add Member</h1>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <TextField
          label={"License Plate"}
          variant="outlined"
          type="text"
          onChange={(e) => setLicensePlate(e.target.value)}
          value={licensePlate}
          disabled={isLoading}
          required
          inputProps={{
            maxLength: 8,
            style: { textTransform: "uppercase" },
          }}
        />{" "}
        <br />
        <TextField
          id="date"
          label="Valid Until"
          type="date"
          required
          onChangeCapture={(e) => setEndDate(e.target.value)}
          defaultValue={endDate}
          className={classes.textField}
          InputLabelProps={{
            shrink: true,
          }}
        />{" "}
        <TextField
          label={"Name"}
          variant="outlined"
          type="text"
          onChange={(e) => setUsername(e.target.value)}
          value={userName}
          disabled={isLoading}
        />{" "}
        <TextField
          label={"Code"}
          variant="outlined"
          type="text"
          onChange={(e) => setUsercode(e.target.value)}
          value={userCode}
          disabled={isLoading}
        />{" "}
        {errorMessages && (
          <div style={{ color: "red", textAlign: "center" }}>
            {errorMessages}
          </div>
        )}
        <div style={{ display: "flex", justifyContent: "center" }}>
          <SubmitMemberButton onClick={(e) => handleSubmit(e)}>
            Submit
          </SubmitMemberButton>
        </div>
      </div>
    </div>
  );
};

const SubmitMemberButton = styled.button`
  height: 50px;
  width: 200px;
  margin-top: 20px;
  cursor: pointer;
  border: none;
  border-radius: 5px;
  background-color: lightcoral;
  color: #fff;
  font-size: 20px;
  font-weight: 600;
  transition: all 250ms cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s;
  &:hover {
    background-color: #f6b2b2;
    color: #fff;
  }
`;

export default AddMemberPage;
