import APIkit from ".";

class CodeService {
  getvalidations(locationId) {
    return APIkit.get(`/api/v1/locations/${locationId}/validations`);
  }

  getAll() {
    return APIkit.get(`/api/v1/validations`);
  }

  get({ locationId, validationId }) {
    return APIkit.get(
      `/api/v1/locations/${locationId}/validations/${validationId}`
    );
  }

  create(data) {
    return APIkit.post("/api/v1/validations", data);
  }

  update(id, data) {
    return APIkit.patch(`/api/v1/validations/${id}`, data);
  }

  delete(id) {
    return APIkit.delete(`/api/v1/validations/${id}`);
  }

  findById(title) {
    return APIkit.get(`/api/v1/validations?id=${title}`);
  }
}

export default CodeService;
