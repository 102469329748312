import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import swal from "sweetalert";
import MembershipComponent from "../../components/MembershipComponent";
import MemberService from "../../services/MemberService";
import CircleLoader from "../../components/shared/CircleLoader";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    // backgroundColor: "#fff",
  },
  mainTable: {
    width: "70vw",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      overflowX: "scroll",
      border: "1px solid #ccc",
      borderRadius: 10,
    },
  },
}));

const Membership = () => {
  const classes = useStyles();
  const history = useHistory();
  const [members, setMembers] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const getTeamMembers = async () => {
    const memberService = new MemberService();
    const { data } = await memberService.getAll();
    setMembers(data.data);
  };

  const deleteMember = async (id) => {
    try {
      // confirm delete
      const confirmDelete = await swal({
        title: "Are you sure?",
        text: "Once deleted, you will not be able to recover this member!",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      });

      if (!confirmDelete) {
        setIsLoading(false);
        return;
      }

      // show loading
      setIsLoading(true);

      // delete member
      const memberService = new MemberService();
      await memberService.delete(id);

      // filter out the deleted member
      const filteredMembers = members.filter((member) => member._id !== id);
      setMembers(filteredMembers);

      // show success message
      swal("Poof! The Member has been deleted!", {
        icon: "success",
      });
    } catch (error) {
      console.log("error", error);
      swal(
        "Oops!",
        "Seems like something went wrong, please try again or Contact Us",
        "error"
      );
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getTeamMembers();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={classes.root}>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <h1 style={{ color: "#27ae60", textAlign: "center" }}>Membership</h1>
        <button
          style={{ marginBottom: 18 }}
          onClick={() => history.push("/members/add")}
        >
          Add Member
        </button>
      </div>
      {isLoading ? (
        <CircleLoader />
      ) : (
        <div className={classes.mainTable}>
          {members.length > 0 && !isLoading ? (
            <MembershipComponent
              members={members}
              deleteMember={deleteMember}
            />
          ) : (
            <div style={{ textAlign: "center" }}>
              Looks like you have not added any members yet.
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Membership;
