import APIkit from ".";

class LocationService {
  getAll() {
    return APIkit.get("/api/v1/locations");
  }

  getMyLocations() {
    return APIkit.get("/api/v1/locations/me");
  }

  getNearby({ lat, lng }) {
    return APIkit.get(`/api/v1/locations/center/${lat},${lng}`);
  }

  getMyAll() {
    return APIkit.get("/api/v1/locations/me");
  }

  getMyServiceLocations() {
    return APIkit.get("/api/v1/locations/service");
  }

  get(id) {
    return APIkit.get(`/api/v1/locations/${id}`);
  }

  create(data) {
    return APIkit.post("/api/v1/locations", data);
  }

  update(id, data) {
    return APIkit.post(`/api/v1/locations/${id}`, data);
  }

  delete(id) {
    return APIkit.delete(`/api/v1/locations/${id}`);
  }

  findById(title) {
    return APIkit.get(`/api/v1/locations?id=${title}`);
  }
}

export default LocationService;
