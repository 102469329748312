import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import LocationIcon from "@material-ui/icons/LocationOn";
import { useHistory } from "react-router-dom";
import TextField from "@material-ui/core/TextField";
import { isEmpty } from "../../utils/FrontEndHelpers";
import styled from "styled-components";
import { CircularProgress } from "@material-ui/core";
import ZoneService from "../../services/ZoneService";
import SessionService from "../../services/SessionService";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    padding: "0 10px",
  },
  addressDiv: {
    marginRight: theme.spacing(1),
  },
  lotDiv: {
    display: "flex",
    justifyContent: "center",
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  plateNumberInput: {
    width: "160px",
  },
  plateStateInput: {
    width: "120px",
  },
  codeInput: {
    width: "100%",
  },
  hoursInput: {
    width: "90px",
    height: "51px",
    marginLeft: "6px !important",
  },
  licenseInputDiv: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
}));

const MemberCheckoutPage = () => {
  const classes = useStyles();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [zone, setZone] = useState([]);
  const [vehiclePlate, setVehiclePlate] = useState("");
  const [vehicleState, setVehicleState] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [code, setCode] = useState("");
  const { locationId, zoneId } = useParams();

  const getZoneInfo = async () => {
    setIsLoading(true);

    const zoneInfo = {
      locationId,
      zoneId,
    };

    try {
      const zoneService = new ZoneService();
      const {
        data: { data },
      } = await zoneService.get(zoneInfo);

      setZone(data);
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getZoneInfo();
    // eslint-disable-next-line
  }, []);

  const handlePark = async () => {
    if (isEmpty(vehiclePlate) || isEmpty(vehicleState)) {
      setErrorMessage("Please provide a valid license plate number and state");
      return;
    }

    if (isEmpty(code)) {
      setErrorMessage("Please provide a valid code");
      return;
    }

    const sessionService = new SessionService();
    try {
      const memberCodeObj = {
        code,
        locationId: zone?.location._id,
        zoneId: zone?.zone._id,
        plateNumber: vehiclePlate,
      };
      const res = await sessionService.memberCode(memberCodeObj);
      if (res.data.success) {
        history.push(
          `/locations/${memberCodeObj.locationId}/zones/${memberCodeObj.zoneId}/members/receipt`
        );
      } else {
        setErrorMessage(res.data.message);
      }
    } catch (err) {
      console.log(err);
      setErrorMessage("Please provide a valid code");
      return;
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      <div
        style={{
          display: "flex",
          fontSize: 30,
          fontWeight: "bold",
          color: "#27ae60",
          justifyContent: "center",
          marginBottom: "30px",
        }}
      >
        OPS Parking
      </div>
      {isLoading ? (
        <CircularProgress />
      ) : !isLoading && !zone.location ? (
        <div style={{ textAlign: "center" }}>Loading...</div>
      ) : (
        <>
          <div style={{ fontSize: 30, fontWeight: 900 }}>
            <div>Zone: {zone.zone.zoneNumber}</div>
          </div>
          <div className={classes.addressDiv}>
            <div
              style={{
                textTransform: "capitalize",
                fontWeight: 900,
                fontSize: 22,
                marginBotton: 6,
              }}
            >
              {zone.location.name}
            </div>
            <div style={{ display: "flex" }}>
              <div style={{ marginRight: 10 }}>
                <LocationIcon fontSize="small" />
              </div>
              <div style={{ fontSize: 12 }}>{zone.location.address}</div>
            </div>
          </div>

          <div className={classes.licenseInputDiv}>
            <TextField
              label={"License Plate Number"}
              name="vehiclePlate"
              classes={{ root: classes.plateNumberInput }}
              InputLabelProps={{
                style: { fontSize: "14px" },
              }}
              inputProps={{
                maxLength: 8,
                style: { textTransform: "uppercase" },
              }}
              variant="outlined"
              onChange={(e) => setVehiclePlate(e.target.value.toUpperCase())}
              value={vehiclePlate}
              disabled={isLoading}
            />{" "}
            <div style={{ width: "20px" }}></div>
            <TextField
              label={"License State"}
              name="vehicleState"
              classes={{ root: classes.plateStateInput }}
              InputLabelProps={{
                style: { fontSize: "14px" },
              }}
              inputProps={{
                maxLength: 2,
                style: { textTransform: "uppercase" },
              }}
              variant="outlined"
              onChange={(e) => setVehicleState(e.target.value.toUpperCase())}
              value={vehicleState}
              disabled={isLoading}
            />{" "}
          </div>

          <div style={{ width: "100% !important" }}>
            <TextField
              label={"CODE"}
              name="code"
              classes={{ root: classes.codeInput }}
              InputLabelProps={{
                style: { fontSize: "14px" },
              }}
              inputProps={{
                maxLength: 20,
                style: { textTransform: "uppercase" },
              }}
              variant="outlined"
              onChange={(e) => setCode(e.target.value.toUpperCase())}
              value={code}
              disabled={isLoading}
            />{" "}
          </div>
          <div
            style={{
              marginTop: "10px",
              fontWeight: "bold",
              fontSize: "18px",
              width: "100%",
            }}
          >
            Estimated Price:{" "}
            <span style={{ color: "#0f4fb8 !important" }}>
              {" "}
              Free For Church Visitors{" "}
            </span>
          </div>
          <div style={{ color: "red", textAlign: "center", height: "20px" }}>
            {errorMessage && errorMessage}
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            {isLoading ? (
              <CircularProgress />
            ) : (
              <ContinueButton onClick={handlePark}>Confirm Park</ContinueButton>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export const ContinueButton = styled.button`
  height: 50px;
  width: 280px;
  margin-top: 20px;
  cursor: pointer;
  border: none;
  border-radius: 5px;
  background-color: #27ae60;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  transition: all 250ms cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s;
  &:hover {
    background-color: #2cc36b;
  }
`;

export default MemberCheckoutPage;
