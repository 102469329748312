import React from "react";

const HelpPage = () => {
  return (
    <div>
      <div
        style={{
          display: "flex",
          fontSize: 30,
          fontWeight: "bold",
          color: "#27ae60",
          justifyContent: "center",
          marginBottom: "30px",
        }}
      >
        ops parking
      </div>
      <h1 style={{ textAlign: "center" }}>Getting Started with Ops Parking</h1>
      <p style={{ color: "#27ae60" }}>How does Ops Parking work?</p>
      <p>
        <br />
        Ops Parking partners with small businesses, hotels, and parking lot
        owners to manage their parking lots.
        <br />
        As a renter, you can rent a spot any time their lot is not in use or
        there are extra spots available.
        <br /> <br />
      </p>
      <p>
        <i>
          *Ops Parking charges an hour minimum for every lot (regardless of if
          you stay less than an hour), and the price is prorated every minute
          after the first hour. With Ops Parking, the driver must start and end
          their own rental. <br /> <br />
        </i>
      </p>
      <p style={{ color: "#27ae60" }}>How do I request a refund?</p>
      <p>
        <br />
        If you have an issue in which you'd like a refund, select &ldquo;Contact
        us&rdquo; and let us know what went wrong.
        <br /> <br />
      </p>
      <p style={{ color: "#27ae60" }}>
        What are the password requirements for an Ops Parking account?
      </p>
      <p>
        <br />
        Password must be between 8-25 characters in length, contain at least 1
        upper case character, 1 lower case character, a number (0-9), and a
        special character (!@#$%^&amp;).
      </p>
      <p style={{ color: "#27ae60" }}>
        Do I have to download an app to use Ops Parking?
      </p>
      <p>
        <br />
        No. You can pay using a mobile web browser by going to the Ops Parking
        site You can also use the Google Pay app to make a parking payment.
      </p>
      <p style={{ color: "#27ae60" }}>
        <br />
        Can I use Ops Parking if my smartphone can&rsquo;t scan the QR Code?
      </p>
      <p>
        <br />
        Yes. As long as you have a cell phone, you can text the number on the
        sign and you will receive a text message with a link to the ops parking
        web app.
      </p>
      <p style={{ color: "#27ae60" }}>
        <br />
        Do I need to turn on-location services to use the Ops parking web app?
      </p>
      <p>
        <br />
        We do recommend turning on location services when using the ops parking
        web app. Doing so will help quickly identify the available parking in
        your area and provide a better overall user experience. However, you are
        not required to turn on-location services to use the app.
      </p>
      <p style={{ color: "#27ae60" }}>
        <br />
        Do I need to create an account to use the Ops Parking app?
      </p>
      <p>
        <br />
        No. If you use the mobile web app at opsparking.com, you do not have to
        create an account and you can checkout as a guest. The full-featured Ops
        Parking app, downloaded from the Apple or Google app stores, requires
        you to create an account.
        <br />
      </p>
      <h1>Paying for Parking</h1>
      <p style={{ color: "#27ae60" }}>
        <br />
        How do I start a rental?
      </p>
      <div>
        <br />
        <p>
          1. Scan QR Code on parking sign OR Text the number on the sign to
          receive a text back with a web app link. Follow the link to the Ops
          Parking web app where you will be able to select your desired duration
          of parking. Make sure you have selected the correct credit card and
          license plate. Please make sure you are clicking on the correct
          license plate that is in the lot or else making a rental for the wrong
          plate can result in immobilization or towing.
        </p>
        <br /> <br />
        <p>
          2. Press the green 'Start rental' button. You will see the active
          rental screen here.
        </p>
        <br />
        <p>
          3. *Note that you can 'set a reminder' for a customizable amount of
          time. This will remind you to end your rental at the given time you
          choose.
        </p>
        <br /> <br />
      </div>
      <p style={{ color: "#27ae60" }}>How do I request a refund?</p>
      <p>
        <br />
        If you have an issue in which you'd like a refund, select &ldquo;Contact
        us&rdquo; and let us know what went wrong.
        <br /> <br />
      </p>
      <p style={{ color: "#27ae60" }}>How do I find parking in my location?</p>
      <p>
        <br />
        In the Ops parking web app, you will see a map view of available parking
        in your area marked by pins on the map that contain zone numbers. This
        shows all the places where you can use Ops Parking to pay for parking.
        Be sure to confirm the correct zone number by identifying it on the Ops
        Parking signs or stickers around the meter or in the area where you are
        parked.
      </p>
      <p style={{ color: "#27ae60" }}>
        <br />
        What is a Zone Number?
      </p>
      <p>
        <br />
        The Ops Parking zone number identifies a specific parking area like a
        particular spot, section of the street, or parking lot. This zone number
        is required to start your parking session. You can find the zone number
        located on stickers and signage around the meter or lot.
      </p>
      <p style={{ color: "#27ae60" }}>
        <br />
        How will enforcement officers know I've paid for my parking session?
      </p>
      <p>
        <br />
        Once you have started a parking session, Ops Parking will record that
        information in our system and make it visible to the enforcement
        officers on site. Enforcement officers will use a handheld device to
        look up your zone number, space number (if needed), and license plate
        number to confirm that you have paid for parking. That is why it is
        always important to verify your license plate in the app before you
        start the parking session.
      </p>
      <p style={{ color: "#27ae60" }}>
        <br />
        Can I extend my parking time?
      </p>
      <p>
        <br />
        In most locations, you can extend your parking time up to the maximum
        allowed for the parking spot. For example, if you are parking in a spot
        that allows for a maximum of 2 hours of parking, you can pay for one
        hour, then extend up to an additional hour. You cannot extend past the
        maximum time allowed. In certain areas, you will pay either a flat rate
        or pay when you leave the parking spot. In these cases, there is no need
        to extend your parking time.
      </p>
      <p style={{ color: "#27ae60" }}>
        <br />
        Where do I find my parking history or receipts?
      </p>
      <p>
        <br />
        You can view your recent parking history in the Ops Parking web app.
        Touch the "My bookings" button in the main navigation. You will see your
        recent parking transactions.
      </p>
      <p style={{ color: "#27ae60" }}>
        <br />
        Can I stop or cancel my parking session?
      </p>
      <p>
        <br />
        Certain zones allow you to "Stop" a parking session. If you are in a
        zone where it is permitted, you will see a "Stop" button on the screen
        with your active parking session. When you leave the parking spot, touch
        the "Stop" button to end your parking session. If you do not see the
        "Stop" button, you cannot stop an active session in that zone. Once you
        start a parking session, you cannot cancel it in the web app.
      </p>
      <p style={{ color: "#27ae60" }}>
        <br />
        Can I change the zone number while in an active parking session?
      </p>
      <p>
        <br />
        Once you start a parking session, you cannot change the zone number in
        the app. If you accidentally entered the wrong zone number, you should
        immediately start another parking session with the correct zone number.
      </p>
      <p style={{ color: "#27ae60" }}>
        <br />
        Can I use the Ops Parking app to pay for more than one car at the same
        time?
      </p>
      <p>
        <br />
        Yes. After you pay for the first car, go back into the app and start
        another parking session for the other vehicle(s). Make sure you select
        the correct license plate for the other car you are paying for before
        starting the parking session. Note, you can pay for up to five different
        vehicles at any one time.
      </p>
      <p style={{ color: "#27ae60" }}>
        <br />
        What if I get a parking ticket?
      </p>
      <p>
        <br />
        If you paid for parking with Ops Parking and still received a ticket,
        you can contest the citation with your local parking authority. The most
        common reason people get tickets when using Ops Parking is that they
        paid for the wrong zone number or the wrong vehicle. You can find the
        information from your parking session in the "My Bookings" section. Use
        this documentation when you dispute the ticket.
      </p>
      <p style={{ color: "#27ae60" }}>
        <br />
        Why do the rates shown in the app not match what I see on the signs in
        my location?
      </p>
      <p>
        <br />
        Local parking authorities and parking operators set the parking rates
        and provide that information to us. If you see a discrepancy with a rate
        in the web app versus the meter, please visit &ldquo;Contact Us&rdquo;
        and open a support ticket. We will need you to provide us with detailed
        information on the issue so we can investigate further.
      </p>
      <p style={{ color: "#27ae60" }}>
        <br />
        When I enter a zone number, the app says, "Flat Rate" and will not allow
        me to select a parking duration. Why is this happening?
      </p>
      <p>
        <br />
        Some lots offer flat-rate pricing for a set amount of time or part of
        the day. For example, a lot may have a $5 flat rate for parking in the
        evening. If it is a flat rate for parking, that message will appear in
        the app, and you will not have the ability to modify the parking
        duration at that specific location. Refer to the signs posted around the
        lot to get more information.
      </p>
      <p style={{ color: "#27ae60" }}>
        <br />
        Why can't I use the credit card I want in certain locations?
      </p>
      <p>
        <br />
        With the Ops Parking app, you can use all major credit cards, PayPal,
        and Apple Pay. However, some cities and operators do not accept certain
        forms of payment.
      </p>
      <p style={{ color: "#27ae60" }}>
        <br />
        What is the Ops Parking transaction fee?
      </p>
      <p>
        <br />
        The transaction fee is what Ops Parking web app charges the user each
        time they pay for parking. This fee is on top of the regular parking
        fee. For example, if you pay for 2 hours of parking and the cost is $2
        per hour, your total cost will be $4 plus the Ops Parking transaction
        fee. The amount of the transaction fees will vary by location.
        <br />
      </p>
      <h1>Managing Your Ops Parking Account</h1>
      <p style={{ color: "#27ae60" }}>Who can I contact for more help?</p>
      <p>
        <br />
        If you have questions or need help click the &ldquo;Contact us&rdquo;
        and submit a support ticket, and one of our representatives will get
        back to you.
      </p>
      <p style={{ color: "#27ae60" }}>
        <br />
        How do I delete my account?
      </p>
      <p>
        <br />
        You&rsquo;ll need to open a support ticket in the &ldquo;Contact
        Us&rdquo; if you would like to delete your account. Please provide your
        mobile number, license plate number, and the last 4 digits of the card
        on file for you. You will receive an email confirmation once the account
        deletion is complete.
      </p>
    </div>
  );
};

export default HelpPage;
