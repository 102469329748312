import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import styled from "styled-components";
import swal from "sweetalert";
import CodeIcon from "@material-ui/icons/Code";
import CodeService from "../../services/CodeService";
import "react-calendar/dist/Calendar.css";
import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";
import LocationService from "../../services/LocationService";
import { capitalize } from "../../utils/FrontEndHelpers";
import CircularIndeterminate from "../../components/shared/CircleLoader";
import { cl } from "../../theme/colors";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 280,
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
  },
  codeCards: {
    display: "grid",
    gridTemplateColumns: "50% 50%",
    gap: "10px",
    [theme.breakpoints.down("sm")]: {
      gridTemplateColumns: "100%",
      justifyItems: "center",
    },
  },
  codeCard: {
    margin: "20px 0",
    width: 250,
    boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: 250,
    borderRadius: 15,
    padding: 15,
  },
  deleteButton: {
    textAlign: "center",
    height: "34px",
    marginRight: "auto",
    marginLeft: "auto",
    color: "red",
    padding: "4px",
    cursor: "pointer",
    border: "1px solid",
    borderRadius: "10px",
    width: "100px",
  },
}));

const Codes = () => {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [locations, setLocations] = useState([]);
  const [location, setLocation] = useState({});
  const [startDate, setStartDate] = useState(new Date("2014-08-18T21:11:54"));
  const [endDate, setEndDate] = useState(new Date("2014-08-18T21:11:54"));
  const [code, setCode] = useState("");
  const [errorMessages, setErrorMessages] = useState("");
  const [errorLocNameMessages, setErrorLocNameMessages] = useState("");
  const [newCodes, setNewCodes] = useState([]);

  const getLocations = async () => {
    const locationService = new LocationService();
    const { data } = await locationService.getMyLocations();
    setLocations(data.data.locations);
  };

  const getCodes = async () => {
    const codeService = new CodeService();
    const { data } = await codeService.getAll();
    const secondArray = data.data;

    if (newCodes?.length === 0) {
      setNewCodes(secondArray);
      return;
    }

    const firstArray = [...newCodes];
    var ids = new Set(firstArray.map((d) => d._id));
    var merged = [
      ...firstArray,
      ...secondArray.filter((d) => !ids.has(d._id) && ids),
    ];

    setNewCodes(merged);
  };

  useEffect(() => {
    try {
      window.scroll({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    } catch (error) {
      // just a fallback for older browsers
      window.scrollTo(0, 0);
    }

    getLocations();
    getCodes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSelect = (val) => {
    setLocation(val.target.value);
  };

  const deleteCode = async (id) => {
    try {
      // confirm delete
      const confirmDelete = await swal({
        title: "Are you sure?",
        text: "Once deleted, you will not be able to recover this member!",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      });

      if (!confirmDelete) {
        setIsLoading(false);
        return;
      }

      // show loading
      setIsLoading(true);

      const codeService = new CodeService();
      await codeService.delete(id);
      const filterdCodes = newCodes.filter((nc) => nc._id !== id);
      setNewCodes(filterdCodes);

      // show success message
      swal("Poof! The Validation Code has been deleted!", {
        icon: "success",
      });

      // remove loading
      setIsLoading(false);
    } catch (err) {
      console.log(err);
      // remove loading
      swal(
        "Oops!",
        "Seems like something went wrong, please try again or Contact Us",
        "error"
      );
      setIsLoading(false);
    }
  };

  const handleSubmit = async () => {
    setIsLoading(true);

    const codesObject = {
      locationId: location._id,
      location,
      code,
      startDate,
      endDate,
    };

    // make call here
    try {
      const codeService = new CodeService();
      const newcodes = await codeService.create(codesObject);
      setNewCodes([...newCodes, newcodes.data.data.code]);
      setErrorMessages("");
      setCode("");
      setStartDate("");
      setEndDate("");
      setLocation({});
    } catch (err) {
      console.log(err);
      setErrorLocNameMessages(err);
      setErrorMessages(err.message);
    }

    setIsLoading(false);
  };

  return (
    <div>
      <div style={{ textAlign: "center" }}>
        <div
          style={{
            textTransform: "capitalize",
            fontSize: "26px",
            fontWeight: "900",
            marginBottom: "50px",
          }}
        >
          Add Validation Code
        </div>
        {/* <span>{location.state.address}</span> */}
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <div>
          <FormControl variant="outlined" style={{ width: "100%" }}>
            <InputLabel
              id="demo-simple-select-outlined-label"
              style={{ paddingLeft: 8 }}
            >
              My Locations
            </InputLabel>
            <Select
              labelId="demo-simple-select-outlined-label"
              className={classes.textField}
              defaultValue={locations.length > 0 ? locations[0]?.id : ""}
              value={location}
              onChange={handleSelect}
              label="Location"
            >
              {locations.map((loc, idx) => {
                return (
                  <MenuItem value={loc} key={idx}>
                    {capitalize(loc.name)}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </div>
        <TextField
          label={"Validation Code"}
          variant="outlined"
          type="text"
          onChange={(e) => setCode(e.target.value)}
          value={code}
          disabled={isLoading}
          inputProps={{
            style: { textTransform: "uppercase" },
          }}
        />{" "}
        <br />
        <hr />
        <div>
          <div>
            <TextField
              id="date"
              label="Start Date"
              type="date"
              onChangeCapture={(e) => setStartDate(e.target.value)}
              defaultValue={startDate}
              className={classes.textField}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <TextField
              id="date"
              label="End Date"
              type="date"
              onChangeCapture={(e) => setEndDate(e.target.value)}
              defaultValue={endDate}
              className={classes.textField}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </div>
        </div>
        {errorLocNameMessages && (
          <div style={{ color: "red", textAlign: "center" }}>
            {errorLocNameMessages}
          </div>
        )}
        {errorMessages && (
          <div style={{ color: "red", textAlign: "center" }}>
            {errorMessages}
          </div>
        )}
        <div style={{ display: "flex", justifyContent: "center" }}>
          <SubmitLocationButton onClick={(e) => handleSubmit(e)}>
            Submit
          </SubmitLocationButton>
        </div>
      </div>

      <h1 style={{ marginTop: 75 }}>Validate Codes</h1>
      <hr />
      {isLoading ? (
        <CircularIndeterminate />
      ) : newCodes?.length === 0 ? (
        <div style={{ textAlign: "center", marginTop: 30, color: "gray" }}>
          Looks like you have not created any codes...
        </div>
      ) : (
        <div className={classes.codeCards}>
          {newCodes?.length > 0 &&
            newCodes.map((ev, idx) => {
              return (
                <div className={classes.codeCard} key={idx}>
                  <div style={{ display: "flex", height: 100 }}>
                    <div
                      style={{
                        height: 36,
                        width: 36,
                        borderRadius: "10px",
                        backgroundColor: cl.senary,
                        marginRight: 10,
                        display: "flex",
                        justifyContent: "center",
                        border: "none",
                      }}
                    >
                      <CodeIcon
                        style={{
                          position: "relative",
                          top: "50%",
                          left: "50%",
                          transform: "translate(-50%, -50%)",
                          marginRight: 15,
                          color: cl.white,
                        }}
                        fontSize="small"
                      />
                    </div>
                    <div>
                      <div style={{ fontWeight: 800 }}>
                        {capitalize(ev.location?.name)}
                      </div>
                      <div style={{ color: cl.nonary, fontSize: 12 }}>
                        {ev.location?.address}
                      </div>
                    </div>
                  </div>
                  <div style={{ height: 50 }}>
                    {moment(ev.startDate).format("MMMM Do YYYY")} -{" "}
                    {moment(ev.endDate).format("MMMM Do YYYY")}
                  </div>
                  <div
                    style={{
                      fontWeight: 800,
                      color: cl.tertiary,
                      fontSize: 25,
                      textAlign: "center",
                      height: 50,
                    }}
                  >
                    {capitalize(ev.code)}
                  </div>
                  <div
                    onClick={() => deleteCode(ev._id)}
                    className={classes.deleteButton}
                  >
                    Delete
                  </div>
                </div>
              );
            })}
        </div>
      )}
    </div>
  );
};

const SubmitLocationButton = styled.button`
  height: 50px;
  width: 200px;
  margin-top: 20px;
  cursor: pointer;
  border: none;
  border-radius: 5px;
  background-color: #27ae60;
  color: #fff;
  font-size: 20px;
  font-weight: 600;
  transition: all 250ms cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s;
  &:hover {
    background-color: #27ae59;
    color: #fff;
  }
`;

export default Codes;
