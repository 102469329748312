import React from "react";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import { cl } from "../../theme/colors";
import { capitalize, formatPrice } from "../../utils/FrontEndHelpers";

const LocationStatCard = ({ lot, goToLotRevenue }) => {
  return (
    <div
      onClick={() => goToLotRevenue(lot)}
      style={{
        margin: "20px 0",
        minWidth: 250,
        // border: `1px solid ${cl.nonary}`,
        cursor: "pointer",
        boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
        borderRadius: 15,
        padding: 15,
      }}
    >
      <div style={{ display: "flex" }}>
        <div
          style={{
            height: 36,
            width: 36,
            borderRadius: "10px",
            backgroundColor: cl.blue1,
            marginRight: 10,
            display: "flex",
            justifyContent: "center",
            border: "none",
          }}
        >
          <LocationOnIcon
            style={{
              position: "relative",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              marginRight: 15,
              color: cl.white,
            }}
            fontSize="small"
          />
        </div>
        <div>
          <div style={{ fontWeight: 800 }}>{capitalize(lot.locationName)}</div>
          <div style={{ color: cl.nonary, fontSize: 12 }}>
            {lot.locationAddress}
          </div>
        </div>
      </div>
      <div
        style={{
          margin: "20px 0 0 0",
          fontWeight: 800,
          color: cl.tertiary,
          fontSize: 25,
          textAlign: "center",
        }}
      >
        {formatPrice(Number(lot.totalCost))}
      </div>
      <div
        style={{
          margin: "10px 0",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          fontSize: 13,
        }}
      >
        {lot.revenueChangePercentage && lot.revenueChangePercentage > 0 && (
          <>
            <ArrowDropUpIcon
              fontSize="large"
              style={{ color: cl.secondary, fontWei: 800, fontSi: 22 }}
            />{" "}
            <div style={{ color: cl.secondary }}>
              {Number(lot.revenueChangePercentage).toFixed(2)}%
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default LocationStatCard;
