import React, { useEffect, useState } from "react";
import {
  Button,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  makeStyles,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import ValetService from "../../services/ValetService";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    fontSize: 40,
    fontWeight: "bold",
    color: "#27ae60",
    justifyContent: "center",
    margin: "30px 0",
    textDecoration: "underline",
  },
  buttonStyle: {
    width: 400,
    justifyContent: "center",
    fontWeight: "bold",
    fontSize: 16,
    backgroundColor: "#27ae60",
    [theme.breakpoints.down("sm")]: {
      minWidth: 280,
    },
    marginBottom: 40,
    padding: 40,
  },
  buttonDiv: {
    display: "flex",
    justifyContent: "center",
    marginTop: 40,
  },
  flexCol: {
    width: "70vw",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  flexRow: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    gap: "10px",
    // breakpoint
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      //   gridTemplateColumns: "1fr 1fr 1fr",
    },
  },
  tempGrid: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr 1fr",
    gap: "4px",
    // breakpoint
    [theme.breakpoints.down("sm")]: {
      gridTemplateColumns: "1fr",
    },
  },
  wFull: {
    width: "100%",
  },
  plateInput: {
    width: "80%",
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
  mb: {
    marginBottom: "16px",
  },
  mt: {
    marginTop: "16px",
  },
}));

const CreateValet = () => {
  const classes = useStyles();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [values, setValues] = useState({
    phoneNumber: "",
    plateNumber: "",
    ticketNumber: "",
    state: "",
    make: "",
    model: "",
    color: "",
    description: "",
    isHourly: false,
    price: "",
    firstName: "",
    lastName: "",
    email: "",
    totalCost: "",
    transmissionType: "Automatic",
  });

  const createValetMethod = async () => {
    setIsLoading(true);
    try {
      const valetService = new ValetService();
      const data = await valetService.create(values);
      if (data.data.valet) {
        history.push("/valet");
      }
    } catch (err) {
      setErrorMessage(err.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSelect = (val) => {
    setValues({ ...values, transmissionType: val.target.value });
  };

  useEffect(() => {
    // get query param from url
    const urlParams = new URLSearchParams(window.location.search);
    const phoneNumber = urlParams.get("phoneNumber");
    const existing = urlParams.get("existing");
    if (phoneNumber) {
      setValues({ ...values, phoneNumber });
    }

    if (existing) {
      // TODO if existing user, get user info and automatically fill in form ?
      // do nothing for now
    }

    // eslint-disable-next-line
  }, []);

  return (
    <div
      style={{
        marginBottom: 50,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div className={classes.root}>Create</div>
      {/* // full form start */}
      <div className={classes.flexCol}>
        <div className={classes.tempGrid}>
          <TextField
            style={{ width: "100%" }}
            label="Ticket Number..."
            type="number"
            name="ticketNumber"
            disabled={isLoading}
            value={values.ticketNumber}
            InputLabelProps={{
              style: { fontSize: "16px" },
            }}
            inputProps={{
              maxLength: 10,
            }}
            required
            variant="outlined"
            onChange={(e) =>
              setValues({
                ...values,
                ticketNumber: e.target.value.toUpperCase(),
              })
            }
          />
          <TextField
            style={{ width: "100%" }}
            label={"Price"}
            variant="outlined"
            type="number"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">$ </InputAdornment>
              ),
            }}
            placeholder={`Price`}
            onChange={(e) => setValues({ ...values, price: e.target.value })}
            value={values.price}
            disabled={isLoading}
            InputLabelProps={{
              style: { fontSize: "16px" },
            }}
          />{" "}
          <TextField
            style={{ width: "100%" }}
            label="Enter Phone Number..."
            type="number"
            name="phoneNumber"
            required
            disabled={isLoading}
            value={values.phoneNumber}
            InputLabelProps={{
              style: { fontSize: "16px" },
            }}
            inputProps={{
              maxLength: 10,
            }}
            variant="outlined"
            onChange={(e) =>
              setValues({
                ...values,
                phoneNumber: e.target.value.toUpperCase(),
              })
            }
          />
        </div>

        {/* // customer personal Info */}
        <div className={classes.flexRow}>
          <TextField
            label="Customer First Name..."
            type="text"
            name="firstName"
            className={classes.wFull}
            disabled={isLoading}
            value={values.firstName}
            InputLabelProps={{
              style: { fontSize: "16px" },
            }}
            required
            variant="outlined"
            onChange={(e) =>
              setValues({ ...values, firstName: e.target.value })
            }
          />
          <TextField
            label="Customer Last Name..."
            type="text"
            name="lastName"
            className={classes.wFull}
            disabled={isLoading}
            value={values.lastName}
            InputLabelProps={{
              style: { fontSize: "16px" },
            }}
            variant="outlined"
            onChange={(e) => setValues({ ...values, lastName: e.target.value })}
          />
        </div>

        {/* // customer vehicle info */}
        <div className={classes.flexCol}>
          <div className={classes.flexRow}>
            <TextField
              label={"License Plate Number"}
              name="vehiclePlate"
              required
              className={classes.plateInput}
              InputLabelProps={{
                style: { fontSize: "16px" },
              }}
              inputProps={{
                maxLength: 8,
                style: { textTransform: "uppercase" },
              }}
              variant="outlined"
              onChange={(e) =>
                setValues({
                  ...values,
                  plateNumber: e.target.value.toUpperCase(),
                })
              }
              value={values.plateNumber}
              disabled={isLoading}
            />{" "}
            <TextField
              label={"License State"}
              name="vehicleState"
              style={{ width: "100%" }}
              InputLabelProps={{
                style: { fontSize: "16px" },
              }}
              inputProps={{
                maxLength: 2,
                style: { textTransform: "uppercase" },
              }}
              required
              variant="outlined"
              onChange={(e) =>
                setValues({
                  ...values,
                  state: e.target.value.toUpperCase(),
                })
              }
              value={values.state}
              disabled={isLoading}
            />{" "}
          </div>

          {/* // vehicle dets */}
          <div className={classes.flexRow}>
            <TextField
              label="Vehicle Make..."
              type="text"
              name="vehicleMake"
              style={{ width: "100%" }}
              disabled={isLoading}
              value={values.make}
              InputLabelProps={{
                style: { fontSize: "16px" },
              }}
              required
              variant="outlined"
              onChange={(e) => setValues({ ...values, make: e.target.value })}
            />
            <TextField
              label="Vehicle Model..."
              type="text"
              name="vehicleModel"
              style={{ width: "100%" }}
              disabled={isLoading}
              value={values.model}
              InputLabelProps={{
                style: { fontSize: "16px" },
              }}
              variant="outlined"
              onChange={(e) => setValues({ ...values, model: e.target.value })}
            />
            <TextField
              label="Vehicle Color..."
              type="text"
              name="vehicleColor"
              style={{ width: "100%" }}
              disabled={isLoading}
              value={values.color}
              InputLabelProps={{
                style: { fontSize: "16px" },
              }}
              required
              variant="outlined"
              onChange={(e) => setValues({ ...values, color: e.target.value })}
            />
          </div>
          <FormControl
            variant="outlined"
            style={{ minWidth: 280, marginTop: 20 }}
          >
            <InputLabel
              id="demo-simple-select-outlined-label"
              placeholder="Select a Location"
            >
              {"Transmission Type"}
            </InputLabel>
            <Select
              labelId="demo-simple-select-outlined-label"
              className={classes.textField}
              defaultValue={values.transmissionType}
              value={values.transmissionType}
              onChange={handleSelect}
              label="Transmission Type"
            >
              <MenuItem
                style={{ textTransform: "capitalize" }}
                value={"Automatic"}
              >
                Automatic
              </MenuItem>
              <MenuItem style={{ textTransform: "capitalize" }} value={"Stick"}>
                Stick
              </MenuItem>
            </Select>
          </FormControl>
        </div>
      </div>

      <div className={classes.buttonDiv}>
        <Button
          variant="contained"
          color="primary"
          className={classes.buttonStyle}
          disableElevation
          onClick={() => createValetMethod()}
          disabled={isLoading}
        >
          Submit
        </Button>
      </div>
      <div>
        {errorMessage && (
          <div style={{ color: "red", textAlign: "center" }}>
            {errorMessage}
          </div>
        )}
      </div>
    </div>
  );
};

export default CreateValet;
