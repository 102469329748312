import APIkit from ".";

class EventService {
  getAll() {
    return APIkit.get(`/api/v1/events`);
  }

  getEvents(locationId) {
    return APIkit.get(`/api/v1/locations/${locationId}/events`);
  }

  get({ locationId, eventId }) {
    return APIkit.get(`/api/v1/locations/${locationId}/events/${eventId}`);
  }

  create(data) {
    return APIkit.post("/api/v1/events", data);
  }

  update(id, data) {
    return APIkit.patch(`/api/v1/events/${id}`, data);
  }

  delete(id) {
    return APIkit.delete(`/api/v1/events/${id}`);
  }

  findById(id) {
    return APIkit.get(`/api/v1//locations/:locationId/events/${id}`);
  }

  findByTitle(title) {
    return APIkit.get(`/api/v1/events?title=${title}`);
  }
}

export default EventService;
