import React, { useState, useEffect } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Check from "@material-ui/icons/Check";
import StepConnector from "@material-ui/core/StepConnector";
import Button from "@material-ui/core/Button";
import SessionInfoPage from "./sessionflow/SessionInfoPage";
import ReceiptPage from "./sessionflow/ReceiptPage";
import SuccessfulBookingPage from "./sessionflow/SuccessfulBookingPage";
import CircleLoader from "../components/shared/CircleLoader";
import {
  getLocalStorage,
  isAuth,
} from "../utils/AuthHelpers/AuthCookieHandler";
import SessionReviewPage from "./sessionflow/SessionReviewPage";
import { useHistory } from "react-router";

const QontoConnector = withStyles({
  alternativeLabel: {
    top: 10,
    left: "calc(-50% + 16px)",
    right: "calc(50% + 16px)",
  },
  active: {
    "& $line": {
      borderColor: "#27ae60",
    },
  },
  completed: {
    "& $line": {
      borderColor: "#27ae60",
    },
  },
  line: {
    borderColor: "#eaeaf0",
    borderTopWidth: 3,
    borderRadius: 1,
  },
})(StepConnector);

const useQontoStepIconStyles = makeStyles({
  root: {
    color: "#eaeaf0",
    display: "flex",
    height: 22,
    alignItems: "center",
  },
  active: {
    color: "#27ae60",
  },
  circle: {
    width: 8,
    height: 8,
    borderRadius: "50%",
    backgroundColor: "currentColor",
  },
  completed: {
    color: "#27ae60",
    zIndex: 1,
    fontSize: 18,
  },
});

function QontoStepIcon(props) {
  const classes = useQontoStepIconStyles();
  const { active, completed } = props;

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
      })}
    >
      {completed ? (
        <Check className={classes.completed} />
      ) : (
        <div className={classes.circle} />
      )}
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    padding: "0 10px",
  },
  stepperClass: {
    "& .MuiStepIcon-active": { color: "#27ae60" },
    "& .MuiStepIcon-completed": { color: "#27ae60" },
    "& .Mui-disabled .MuiStepIcon-root": { color: "#27ae60" },
  },
  button: {
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(3),
  },
  instructions: {
    display: "flex",
    justifyContent: "center",
    // height: "322px",
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  icon: {
    fill: "#27ae60",
  },
  text: {
    fill: "white",
  },
  logo: {
    display: "flex",
    cursor: "pointer",
    fontSize: "30px",
    fontWeight: "bold",
    color: "#27ae60",
    justifyContent: "center",
    marginBottom: "30px",
  },
}));

const getSteps = () => ["Session Info", "Payment details", "Receipt"];

const SessionCheckoutPage = ({ zoneObject }) => {
  const history = useHistory();
  const steps = getSteps();
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const [vehicleData, setVehicleData] = useState({});
  const [sessionData, setSessionData] = useState({
    hours: 1,
    isHourly: "",
    vehiclePlate: "",
    vehicleState: "",
    rate: "",
    vehicleMake: "",
    vehicleModel: "",
    vehicleYear: "",
    vehicleDescription: "",
    vehicleImageUrl: "",
    locationId: "",
    startTime: "",
    endTime: "",
    totalCost: "",
    zoneId: "",
    fees: "",
    locationName: "",
    locationAddress: "",
    zoneNumber: "",
  });

  console.log("line 154", zoneObject.zone.price);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
    history.push("/bookings");
  };

  const setUp = () => {
    // Grab user from local storage
    let user;
    try {
      const usr = getLocalStorage("user");
      if (usr) user = usr._id;
    } catch (err) {
      user = `Guest-${Date.now()}`;
    }

    setSessionData({
      ...sessionData,
      user,
      isHourly: zoneObject.zone.isHourly,
      rate: zoneObject.zone.price,
      locationId: zoneObject?.location._id,
      zoneId: zoneObject?.zone._id,
      locationName: zoneObject?.location.name,
      locationAddress: zoneObject?.location.address,
      zoneNumber: zoneObject?.zone.zoneNumber,
    });
  };

  useEffect(() => {
    setUp();
    // eslint-disable-next-line
  }, []);

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <SessionInfoPage
            zoneObj={zoneObject}
            handleNext={handleNext}
            handleBack={handleBack}
            sessionData={sessionData}
            setSession={setSessionData}
            setVehicleData={setVehicleData}
          />
        );
      case 1:
        return (
          <SessionReviewPage
            handleNext={handleNext}
            handleBack={handleBack}
            zoneObj={zoneObject}
            sessionData={sessionData}
            setSession={setSessionData}
          />
        );
      case 2:
        return (
          <ReceiptPage
            zoneObj={zoneObject}
            handleNext={handleNext}
            handleBack={handleBack}
            sessionData={sessionData}
            setSession={setSessionData}
            vehicleData={vehicleData}
          />
        );
      default:
        return;
    }
  };

  return (
    <div className={classes.root}>
      <div className={classes.logo}>ops parking</div>
      <Stepper
        alternativeLabel
        activeStep={activeStep}
        connector={<QontoConnector />}
        className={classes.stepperClass}
      >
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel
              StepIconComponent={QontoStepIcon}
              StepIconProps={{
                classes: {
                  active: classes.icon,
                  text: classes.text,
                },
              }}
            ></StepLabel>
          </Step>
        ))}
      </Stepper>
      <div>
        {Object.keys(zoneObject).length > 0 ? (
          <>
            {activeStep === steps.length ? (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  textAlign: "center",
                }}
              >
                <SuccessfulBookingPage />
                <div>Parking Session Successfully started!</div>
                {isAuth() && (
                  <Button onClick={handleReset} className={classes.button}>
                    Booking History
                  </Button>
                )}
              </div>
            ) : (
              <div style={{ display: "flex", flexDirection: "column" }}>
                <div className={classes.instructions}>
                  {getStepContent(activeStep)}
                </div>
              </div>
            )}
          </>
        ) : (
          <CircleLoader />
        )}
      </div>
    </div>
  );
};

export default SessionCheckoutPage;
