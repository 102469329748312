import React, { useEffect, useState } from "react";
// import TextField from "@material-ui/core/TextField";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import LocationService from "../../services/LocationService";
import CircularIndeterminate from "../../components/shared/CircleLoader";
import { capitalize } from "../../utils/FrontEndHelpers";
import { Button } from "@material-ui/core";

const ManagementPage = ({ history }) => {
  const [isLoading, setLoading] = useState(false);
  const [locations, setLocations] = useState([]);
  const [location, setLocation] = useState("");
  const [selected, setSelected] = useState(false); // boolean
  //   const [values, setValues] = useState({
  //     lotName: "",
  //     lotAddress: "",
  //     lotCity: "",
  //     lotState: "",
  //     lotRevenue: "",
  //     lotCapacity: "",
  //     lotOccupancy: "",
  //     errorMessage: "",
  //   });

  useEffect(() => {
    setLoading(true);
    const locationService = new LocationService();
    const getLocation = async () => {
      const { data } = await locationService.getAll();
      console.log(data.data.locations);
      setLocations(data.data.locations);
    };
    getLocation();
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //   const handleSubmit = (e) => {
  //     e.preventDefault();
  //     setLoading(true);
  //     console.log("Submitted");
  //     setLoading(false);
  //   };

  const goToPage = () => {
    history.push({
      pathname: `/revenue/lot/${location}/new`,
    });
  };

  //   const handleChange = (e) => {
  //     setValues({ ...values, [e.target.name]: e.target.value });
  //   };

  const handleSelect = (event) => {
    setSelected(true);
    setLocation(event.target.value);
  };

  //   const updateRevenue = async () => {
  //     const locationService = new LocationService();
  //     const { data } = await locationService.updateRevenue({
  //       locationId: location,
  //       lotRevenue: values.lotRevenue,
  //     });
  //     console.log(data);
  //   };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: 20,
        alignItems: "center",
      }}
    >
      {isLoading ? (
        <CircularIndeterminate />
      ) : locations.length > 0 ? (
        <>
          <FormControl variant="outlined" style={{ width: "100%" }}>
            <InputLabel id="demo-simple-select-outlined-label">
              Location
            </InputLabel>
            <Select
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              value={location}
              onChange={handleSelect}
              label="Location"
            >
              {locations.map((location, idx) => {
                return (
                  <MenuItem value={location._id} key={idx}>
                    {capitalize(location.name)}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          <div>
            {selected && (
              <Button
                variant="contained"
                color="primary"
                disabled={isLoading}
                onClick={goToPage}
                style={{ color: "#fff", width: "280px", marginBottom: "20px" }}
              >
                Add Session
              </Button>
            )}
          </div>
        </>
      ) : (
        <div>No locations found</div>
      )}
    </div>
  );
};

export default ManagementPage;
