import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Redirect, Link } from "react-router-dom";
import TextField from "@material-ui/core/TextField";
import { useHistory } from "react-router-dom";
import axios from "axios";
import styled from "styled-components";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { authenticate, isAuth } from "../utils/AuthHelpers/AuthCookieHandler";
// import GoogleLoginButton from "../components/shared/GoogleLoginButton";
// import { PageView, userSignUp } from "../../shared/GoogleAnalytics";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  header_text: {
    color: "#000",
  },
  formControl: {
    margin: "8px 0",
    width: "100%",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const RegisterPage = () => {
  const history = useHistory();
  useEffect(() => {
    try {
      window.scroll({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    } catch (error) {
      // just a fallback for older browsers
      window.scrollTo(0, 0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const classes = useStyles();
  const [role, setRole] = useState("customer");
  const [values, setValues] = useState({
    name: "",
    email: "",
    password: "",
    confirm: "",
    buttonText: "Sign Up",
    error: "",
    success: "",
    isLoading: false,
  });

  const { name, email, password, confirm, buttonText, isLoading, error } =
    values;

  const handleChange = (name) => (event) => {
    setValues({ ...values, [name]: event.target.value });
  };

  const handleSelect = (event) => {
    setRole(event.target.value);
  };

  const roleToggle = () => {
    return (
      <FormControl variant="outlined" className={classes.formControl}>
        <InputLabel id="demo-simple-select-outlined-label">Role</InputLabel>
        <Select
          labelId="demo-simple-select-outlined-label"
          id="demo-simple-select-outlined"
          value={role}
          onChange={handleSelect}
          label="Role"
        >
          <MenuItem value={"customer"}>User</MenuItem>
          <MenuItem value={"vendor"}>Vendor</MenuItem>
        </Select>
      </FormControl>
    );
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setValues({ ...values, buttonText: "Loading...", isLoading: true });
    axios
      .post("/api/v1/users/signup", {
        name,
        email,
        password,
        passwordConfirm: confirm,
        role,
      })
      .then((response) => {
        authenticate(response, () => {
          setValues({
            ...values,
            name: "",
            email: "",
            password: "",
            confirm: "",
            buttonText: "Done!",
            error: "",
            isLoading: false,
            submitted: true,
          });
          isAuth() ? history.push("/dashboard") : history.push("/login");
          window.location.reload(false);
        });
      })
      .catch((error) => {
        // console.log("SIGNUP_ERROR", error.response?.data?.error.message);
        setValues({
          ...values,
          buttonText: "Sign Up",
          isLoading: false,
          error: error.response?.data?.error.message,
        });
      });
  };

  return (
    <div>
      {isAuth() && isAuth().role ? <Redirect to="/dashboard" /> : null}
      <div>
        <div>
          <img
            style={{ width: 280 }}
            src="https://d1kyth5cj5s868.cloudfront.net/OPS-parking-logo-b.png"
            alt="opsparking"
          />
        </div>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <TextField
              label="Full Name"
              onChange={handleChange("name")}
              type="text"
              name="name"
              disabled={isLoading}
              value={name}
              variant="outlined"
            />
          </div>

          <div className="form-group">
            <TextField
              label="E-mail"
              onChange={handleChange("email")}
              type="email"
              name="email"
              disabled={isLoading}
              value={email}
              variant="outlined"
            />
          </div>

          <div className="form-group">
            <TextField
              label="Password"
              onChange={handleChange("password")}
              type="password"
              name="password"
              disabled={isLoading}
              value={password}
              variant="outlined"
            />
          </div>

          <div className="form-group">
            <TextField
              label="Confirm Password"
              onChange={handleChange("confirm")}
              type="password"
              name="confirm"
              disabled={isLoading}
              value={confirm}
              variant="outlined"
            />
          </div>

          <div>{roleToggle()}</div>

          {error && (
            <div
              style={{
                fontSize: 12,
                color: "red",
                textAlign: "center",
                width: 280,
                height: 20,
                marginTop: "10px",
              }}
            >
              {error}
            </div>
          )}

          <div style={{ textAlign: "center", marginTop: "4px" }}>
            <SignUpButton
              disabled={isLoading}
              className="btn btn-primary"
              onClick={handleSubmit}
              variant="contained"
              color="primary"
            >
              {buttonText}
            </SignUpButton>
            {/* <GoogleLoginButton /> */}
          </div>
        </form>
        <div style={{ marginTop: "27px", textAlign: "center" }}>
          Have an account already? <Link to="/login">Log In</Link>
        </div>
      </div>
    </div>
  );
};

const SignUpButton = styled.button`
  height: 50px;
  width: 280px;
  margin-top: 20px;
  cursor: pointer;
  border: none;
  border-radius: 5px;
  background-color: #27ae60;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  transition: all 250ms cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s;
  &:hover {
    background-color: #2cc36b;
  }
`;

export default RegisterPage;
