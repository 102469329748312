import React, { useState, useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import styled from "styled-components";
import jwt from "jsonwebtoken";
import axios from "axios";
import CircleLoader from "../components/shared/CircleLoader";

const ResetPassword = ({ match }) => {
  const [error, setError] = useState("");
  const [invalidToken, setInvalidToken] = useState(false);
  const [values, setValues] = useState({
    token: "",
    password: "",
    confirm: "",
    resetComplete: false,
    isLoading: false,
  });

  const { name, token, resetComplete, password, confirm, isLoading } = values;

  const handleChange = (name) => (event) => {
    setValues({ ...values, [name]: event.target.value });
  };

  const handleSubmit = async () => {
    if (!password || !confirm) {
      setError("Please provide a valid email address.");
      return;
    }

    if (password !== confirm) {
      setError("Looks like the passwords are not matching.");
      return;
    }

    try {
      setValues({ ...values, isLoading: true });
      await axios.patch(`/api/v1/users/reset-password/${token}`, {
        token,
        password,
      });
      setValues({ ...values, isLoading: false, resetComplete: true });
    } catch (err) {
      console.log(err);
      setValues({ ...values, isLoading: false });
    }
  };

  useEffect(() => {
    try {
      let token = match.params.token;
      console.log(token);
      // let { name } = jwt.decode(token);
      if (token) {
        let valid = jwt.decode(token);
        console.log(valid);
        if (valid) {
          const { name } = valid;
          setValues({ ...values, name, token });
        } else {
          setInvalidToken(true);
        }
      } else {
        setInvalidToken(true);
      }
    } catch (err) {
      console.log("should set invalid");
      setInvalidToken(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginTop: "100px",
      }}
    >
      <div
        style={{
          display: "flex",
          fontSize: 30,
          fontWeight: "bold",
          color: "#27ae60",
          justifyContent: "center",
          marginBottom: "30px",
        }}
      >
        ops parking
      </div>
      {invalidToken ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            marginTop: "50px",
            color: "red",
          }}
        >
          Invalid token provided. Please check you email again again.
          <div style={{ marginTop: "26px", color: "navy" }}>
            <a href="/login">Go Back to Log In</a>
          </div>
        </div>
      ) : (
        <>
          {resetComplete ? (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                marginTop: "50px",
                color: "green",
              }}
            >
              Password has been reset! Please provide your new credentials at
              the Log In page to continue.
              <div style={{ marginTop: "26px", color: "navy" }}>
                <a href="/login">Go Back to Log In</a>
              </div>
            </div>
          ) : (
            <>
              <div>
                <div
                  style={{
                    textAlign: "center",
                    fontWeight: 700,
                    fontSize: "22px",
                  }}
                >
                  Reset Password
                </div>
                <p style={{ width: "280px", textAlign: "center" }}>
                  Hi {name}, Please provide your new password and click reset
                  password.
                </p>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <TextField
                    label="New Password"
                    variant="outlined"
                    type="password"
                    name="password"
                    value={password}
                    onChange={handleChange("password")}
                    placeholder="Enter your email here..."
                    required
                    style={{ width: "280px", marginTop: "20px" }}
                  />
                  <TextField
                    label="Confirm Password"
                    variant="outlined"
                    type="password"
                    name="confirm"
                    value={confirm}
                    onChange={handleChange("confirm")}
                    placeholder="Enter your email here..."
                    required
                    style={{ width: "280px", marginTop: "20px" }}
                  />
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    {isLoading ? (
                      <CircleLoader />
                    ) : (
                      <ResetButton onClick={handleSubmit}>
                        Reset Password
                      </ResetButton>
                    )}
                  </div>
                  <div
                    style={{
                      textAlign: "center",
                      color: "red",
                      fontSize: "12px",
                      width: "280px",
                      marginTop: "10px",
                    }}
                  >
                    {error && error}
                  </div>
                </div>
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};

const ResetButton = styled.button`
  height: 50px;
  width: 280px;
  margin-top: 20px;
  cursor: pointer;
  border: none;
  border-radius: 5px;
  background-color: #27ae60;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  transition: all 250ms cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s;
  &:hover {
    background-color: #2cc36b;
  }
`;

export default ResetPassword;
